<template>
    <div>
        <v-dialog v-model="isShowDialog">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on"> 操作 </v-btn>
            </template>
            <v-card class="py-2">
                <v-container class="my-1">
                    <v-row class="justify-end">
                        <v-btn color="success" @click="getApplicationForm">列印申請單</v-btn>
                    </v-row>
                </v-container>
                <v-container class="info-container">
                    <v-row class="each-item-row">
                        <v-col class="same-category-each-cell pa-1">訂單編號</v-col>
                        <v-col class="same-category-each-cell pa-1">{{ order.uuid }}</v-col>
                        <v-col class="same-category-each-cell pa-1">校區</v-col>
                        <v-col class="same-category-each-cell pa-1">{{ order.user.area }}</v-col>
                        <v-col class="same-category-each-cell pa-1">申請日期</v-col>
                        <v-col class="pa-1">{{ order.create_date | dateFormat }}</v-col>
                    </v-row>
                    <v-row class="each-item-row">
                        <v-col class="same-category-each-cell pa-1">學號</v-col>
                        <v-col class="same-category-each-cell pa-1">{{ order.user.account }}</v-col>
                        <v-col class="same-category-each-cell pa-1">中文姓名</v-col>
                        <v-col class="same-category-each-cell pa-1">{{ order.user.name }}</v-col>
                        <v-col class="same-category-each-cell pa-1">英文姓名</v-col>
                        <v-col class="pa-1">{{ order.user.name_en }}</v-col>
                    </v-row>
                    <v-row class="each-item-row">
                        <v-col class="same-category-each-cell pa-1">身分證字號</v-col>
                        <v-col class="same-category-each-cell pa-1">{{ order.user.personal_id }}</v-col>
                        <v-col class="same-category-each-cell pa-1">連絡電話</v-col>
                        <v-col class="same-category-each-cell pa-1">{{ order.user.phone }}</v-col>
                        <v-col class="same-category-each-cell pa-1">出生年月日</v-col>
                        <v-col class="pa-1">{{ order.user.birthday | dateFormat }}</v-col>
                    </v-row>
                    <v-row class="each-item-row">
                        <v-col class="pa-1">
                            <p>科系：{{ order.user.department }} &nbsp;班級：{{ order.user.class_name }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="each-item-row" style="background-color: #ffe66f">
                        <v-col class="pa-1">申請內容</v-col>
                    </v-row>
                    <v-row class="each-item-row">
                        <v-col cols="3" class="same-category-each-cell pa-1">申請項目</v-col>
                        <v-col cols="1" class="same-category-each-cell pa-1">單價</v-col>
                        <v-col cols="1" class="same-category-each-cell pa-1">份數</v-col>
                        <v-col cols="1" class="same-category-each-cell pa-1">合計</v-col>
                        <v-col cols="3" class="same-category-each-cell pa-1">備註</v-col>
                        <v-col cols="3" class="pa-1">操作</v-col>
                    </v-row>
                    <v-row class="each-item-row" v-for="(orderDetail, index) in order.order_detail" :key="index">
                        <v-col cols="3" class="same-category-each-cell pa-1">{{ orderDetail.document }}</v-col>
                        <v-col cols="1" class="same-category-each-cell pa-1">{{ orderDetail.unit_price }}</v-col>
                        <v-col cols="1" class="same-category-each-cell pa-1">{{ orderDetail.amount }}</v-col>
                        <v-col cols="1" class="same-category-each-cell pa-1">{{ orderDetail.sub_total }}</v-col>
                        <v-col cols="3" class="same-category-each-cell pa-1">{{ orderDetail.remark }}</v-col>
                        <v-btn cols="3" class="pa-1" v-if="orderDetail.document == '中文歷年成績單' && orderDetail.remark != '含名次、百分比'" color="success" @click="getTranscrips(orderDetail.id)">歷屆成績單列印</v-btn>
                        <v-btn cols="3" class="pa-1" v-if="orderDetail.document == '中文歷年成績單' && orderDetail.remark == '含名次、百分比'" color="success" @click="getTranscrips(orderDetail.id)">歷屆成績單(含名次、百分比)列印</v-btn>
                        <v-btn cols="3" class="pa-1" v-if="orderDetail.document == '中文單學期成績單' && orderDetail.needTermTranscripsRank == false" color="success" @click="getTermTranscrips(orderDetail.id)">單學期成績單列印</v-btn>
                        <v-btn cols="3" class="pa-1" v-if="orderDetail.document == '中文單學期成績單' && orderDetail.needTermTranscripsRank == true" color="success" @click="getTermTranscrips(orderDetail.id)">單學期成績單(含名次、百分比)列印</v-btn>
                    </v-row>
                    <v-row class="each-item-row">
                        <v-col cols="5" class="same-category-each-cell pa-1">總計</v-col>
                        <v-col cols="1" class="same-category-each-cell pa-1">{{ order.price }}</v-col>
                    </v-row>
                    <v-row class="each-item-row" style="background-color: #ffe66f">
                        <v-col class="pa-1">領取方式</v-col>
                    </v-row>
                    <v-row class="each-item-row">
                        <v-col cols="4" class="same-category-each-cell pa-1">{{ order.delivery }}</v-col>
                        <v-col cols="8" v-if="order.order_delivery !== null" class="pa-1">{{ order.order_delivery.address }}</v-col>
                        <v-col cols="8" v-if="order.order_delivery == null" class="pa-1">{{ order.area }}校區</v-col>
                    </v-row>
                    <v-row class="each-item-row" style="background-color: #8cea00">
                        <v-col class="pa-1">審核作業</v-col>
                    </v-row>
                    <v-row class="each-item-row">
                        <v-col class="same-category-each-cell pa-1">狀態</v-col>
                        <v-col class="pa-1">{{ order.progress }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="same-category-each-cell pa-1">更新</v-col>
                        <v-col class="pa-1">
                            <v-btn v-if="order.next_progress" @click="orderUpdateNextProgress" :loading="updateProgressButtonLoading">{{ order.next_progress.name }}</v-btn>
                            <pickinqrcodedialog v-if="order.next_progress" :order="order" :qrcodestatus="qrcodestatus"></pickinqrcodedialog>
                            <v-btn v-if="order.next_progress" color="success" @click="updateQrcode">更新置物狀態</v-btn>
                            <!-- <v-btn color="success" v-if="order.next_progress" @click="getlockerqrcode">產生QR-code</v-btn> -->
                        </v-col>
                    </v-row>
                </v-container>
                <v-container class="my-1">
                    <v-row class="align-center">
                        <v-btn v-if="this.order.result == 0" color="error" @click="conclusion">結案</v-btn>
                        <v-btn v-if="this.order.result == 0" color="primary" @click="revoke" :disabled="!revokeReasonIsNull">撤案</v-btn>
                        <span>撤案原因：</span>
                        <v-text-field v-model="revokeReason" dense outlined hide-details="auto"></v-text-field>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "../api/api.js";
import moment from "moment";
import pickinqrcodedialog from "../components/PickInQrcodeDialog.vue";
// import pdf from "vue-pdf";

export default {
    name: "ManagementOrderDialog",
    components: {
        pickinqrcodedialog,
        // pdf,
    },
    props: {
        pageActions: Object,
        order: Object,
        progresses: Array,
    },

    data() {
        return {
            isShowDialog: false,
            revokeReason: this.order.revoke_reason,
            updateProgressButtonLoading: false,
            orderStatusNow: false,
            qrcodestatus: [],
            src: "",
            // qrcode: false,
        };
    },

    filters: {
        dateFormat: function (value) {
            if (value) {
                return moment(String(value)).format("YYYY/MM/DD");
            }
        },
    },
    mounted() {
        // this.orderstatus();
        // this.orderUpdateNextProgress();
        // let da = "";
        // let datas = "data:application/pdf;base64," + da;
        // this.src = pdf.createLoadingTask({ url: datas });
        // this.getTranscrips();
    },
    computed: {
        revokeReasonIsNull() {
            if (this.revokeReason == null || this.revokeReason == "") {
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        enumPaidStatus(paid) {
            return paid === true ? "已付款" : "未付款";
        },
        // qrcodeID() {
        //     if (this.order.next_progress.name == "準備中") {
        //         // this.qrcode = true;
        //         return true;
        //     }
        // },
        dataURLtoBlob(dataurl) {
            var bstr = atob(dataurl);
            var n = bstr.length;
            var u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: "application/pdf" });
        },
        orderUpdateNextProgress() {
            this.updateProgressButtonLoading = true;

            api.orderUpdateNextProgress({
                id: this.order.id,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.$toasted.show("更新資料成功!", {
                            type: "success",
                            duration: 2000,
                        });
                        this.$emit("getOrders");
                    } else {
                        this.$toasted.show("更新資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("更新資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.updateProgressButtonLoading = false;
                });
        },

        updateQrcode() {
            api.updateqrcode({})
                .then(response => {
                    if (response.status === 200) {
                        this.$toasted.show("更新置物狀態成功!", {
                            type: "success",
                            duration: 2000,
                        });
                        this.$emit("getOrders");
                    } else {
                        this.$toasted.show("更新置物狀態失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("更新置物狀態失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
            api.getlockerqrcode({
                order_id: this.order.id,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.qrcodestatus = response.data;
                        console.log(this.order.id);
                        console.log(this.qrcodestatus);
                        this.order.qrcode.locker_seat = this.qrcodestatus.locker_seat;
                        // this.$toasted.show("更新置物狀態成功!", {
                        //     type: "success",
                        //     duration: 2000,
                        // });
                    } else {
                        this.$toasted.show("更新置物狀態失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("更新置物狀態失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },
        getTranscrips(orderDetailID) {
            api.getTranscrips({
                studentID: this.order.user.account,
                orderDetailID: orderDetailID,
            })
                .then(response => {
                    if (response.status === 200) {
                        let TranscripsURL = response.data;
                        for (let index = 0; index < TranscripsURL.transcrips.length; index++) {
                            const element = TranscripsURL.transcrips[index];
                            window.open(element, `${index}`);
                        }
                        this.$toasted.show("讀取成績單成功!", {
                            type: "success",
                            duration: 2000,
                        });
                        this.$emit("getOrders");
                    } else {
                        this.$toasted.show("更新成績單失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("更新成績單失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        getTermTranscrips(orderDetailID) {
            api.getTermTranscrips({
                studentID: this.order.user.account,
                orderDetailID: orderDetailID,
            })
                .then(response => {
                    if (response.status === 200) {
                        let TranscripsURL = response.data;
                        for (let index = 0; index < TranscripsURL.transcrips.length; index++) {
                            const element = TranscripsURL.transcrips[index];
                            window.open(element, `${index}`);
                        }
                        this.$toasted.show("讀取成績單成功!", {
                            type: "success",
                            duration: 2000,
                        });
                        this.$emit("getOrders");
                    } else {
                        this.$toasted.show("更新成績單失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("更新成績單失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        orderstatus() {
            if (this.order.next_progress.id == 6) {
                this.orderStatusNow = true;
            } else if (this.order.next_progress.id == 7) {
                this.orderStatusNow = true;
            }
        },

        revoke() {
            api.revokeOrder({
                id: this.order.id,
                reason: this.revokeReason,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.$toasted.show("撤銷訂單成功", {
                            type: "success",
                            duration: 2000,
                        });
                        this.$emit("getOrders");
                    } else {
                        this.$toasted.show("撤銷訂單失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("撤銷訂單失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        getApplicationForm() {
            api.getApplicationForm({
                id: this.order.id,
            })
                .then(response => {
                    if (response.status === 200) {
                        window.open(response.data[0]);
                        this.$toasted.show("列印申請表成功", {
                            type: "success",
                            duration: 2000,
                        });
                        this.$emit("getOrders");
                    } else {
                        this.$toasted.show("列印申請表失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("列印申請表失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        conclusion() {
            api.conclusionOrder({
                id: this.order.id,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.$toasted.show("訂單已結案", {
                            type: "success",
                            duration: 2000,
                        });
                        this.$emit("getOrders");
                    } else {
                        this.$toasted.show("結案失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("結案失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },
    },
};
</script>

<style scoped>
.col {
    display: flex;
    align-items: center;
    justify-content: start;
}

.info-container {
    border: 2px solid #000000;
}

.same-category-each-cell {
    border-right: 1px solid #000000;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}

.different-category-row {
    border-bottom: 2px solid #000000;
}

.v-btn {
    margin: 2px;
}
</style>
