<template>
    <div>
        <v-card>
            <v-tabs v-model="documentClassesTabs" align-with-title background-color="cyan" show-arrows>
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab v-for="(documentClass, index) in documentClasses" :key="index">
                    {{ documentClass.name }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="documentClassesTabs">
                <v-tab-item v-for="(documentClass, index) in documentClasses" :key="index">
                    <v-container>
                        <v-row v-if="!documentClass.document.length">
                            <v-col>目前尚無文件</v-col>
                        </v-row>
                        <div v-if="documentClass.document.length && !isMobile">
                            <v-row class="each-item-row container-header">
                                <v-col cols="4" class="same-category-each-cell">文件名稱</v-col>
                                <v-col cols="4" class="same-category-each-cell">ecnt</v-col>
                                <v-col cols="1" class="same-category-each-cell">金額</v-col>
                                <v-col cols="1" class="same-category-each-cell">顯示</v-col>
                                <v-col cols="2">操作</v-col>
                            </v-row>
                            <v-row v-for="(document, index) in documentClass.document" :key="index" class="each-item-row">
                                <v-col cols="4" style="justify-content: flex-start" class="text-left">{{ document.name }}</v-col>
                                <v-col cols="4" style="justify-content: flex-start" class="text-left">{{ document.ecnt }}</v-col>
                                <v-col cols="1">$ {{ document.price }}</v-col>
                                <v-col cols="1">{{ document.valid === true ? "是" : "否" }}</v-col>
                                <v-col cols="2">
                                    <modifyDocumentDialog :pageActions="pageActions" :document="document" :documentClass="documentClass" :accounts="accounts"></modifyDocumentDialog>
                                </v-col>
                            </v-row>
                        </div>
                        <div v-if="documentClass.document.length && isMobile">
                            <v-row class="each-item-row container-header">
                                <v-col cols="9" class="same-category-each-cell">文件名稱</v-col>
                                <v-col cols="3">操作</v-col>
                            </v-row>
                            <v-row v-for="(document, index) in documentClass.document" :key="index" class="each-item-row">
                                <v-col cols="9">{{ document.name }}</v-col>
                                <v-col cols="3">
                                    <modifyDocumentDialog :pageActions="pageActions" :document="document" :documentClass="documentClass" :accounts="accounts"></modifyDocumentDialog>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row>
                            <v-col style="display: flex" class="justify-center mt-2">
                                <v-btn color="success" v-if="pageActions.data_setting_document_add" @click="createDocumentDialog(documentClass, accounts)"> 新增文件 </v-btn>
                                <modifyDocumentClassDialog :pageActions="pageActions" :documentClass="documentClass" @deleteDocumentClass="deleteDocumentClass"></modifyDocumentClassDialog>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <createDocumentDialog ref="createDocument" @getDocumentClasses="getDocumentClasses"></createDocumentDialog>
    </div>
</template>

<script>
// import api from "../api/api.js";
import modifyDocumentClassDialog from "../components/ModifyDocumentClassDialog.vue";
import createDocumentDialog from "../components/CreateDocumentDialog.vue";
import modifyDocumentDialog from "../components/ModifyDocumentDialog.vue";

export default {
    name: "DocumentClassTabs",

    components: {
        createDocumentDialog,
        modifyDocumentClassDialog,
        modifyDocumentDialog,
    },

    props: {
        pageActions: Object,
        documentClasses: Array,
        accounts: Array,
    },

    data() {
        return {
            isMobile: [],
            documentClassesTabs: 0,
        };
    },

    methods: {
        deleteDocumentClass(documentClass) {
            this.documentClasses.splice(this.documentClasses.indexOf(documentClass), 1);
        },

        getDocumentClasses() {
            this.$emit("getDocumentClasses");
        },

        checkIsMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            return flag;
        },
        createDocumentDialog(documentClass, accounts) {
            this.$refs.createDocument.init(documentClass, accounts);
        },
    },

    mounted() {
        this.isMobile = this.checkIsMobile();
    },
};
</script>

<style scoped>
.col {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-header {
    background-color: #dedede;
}

.same-category-each-cell {
    border-right: 1px solid #efefef;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}
</style>
