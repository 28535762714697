<template>
    <div>
        <v-dialog v-model="isShowDeleteConfirmDialog" width="70%">
            <template v-slot:activator="{ on, attrs }">
                <v-btn small color="primary" v-bind="attrs" v-on="on"
                    >取貨QR-code
                    <v-icon>mdi-qrcode-scan</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> <v-icon>mdi-key-variant</v-icon> 取物QR-code 和 密碼 </v-card-title>
                <!-- <v-card-text class="d-flex flex-wrap"> -->
                <!-- <vue-qr text="Hello world!" :size="200"></vue-qr> -->
                <!-- <qrcode-vue :value="order.qrcode.pickUpQRCode" :size="size" level="H" /> -->
                <!-- </v-card-text> -->
                <v-container>
                    <v-row class="container-header each-item-row">
                        <v-col cols="4" class="same-category-each-cell">QR-code</v-col>
                        <v-col cols="4" class="same-category-each-cell">密碼</v-col>
                        <v-col cols="2" class="same-category-each-cell">置物櫃號碼</v-col>
                    </v-row>
                    <v-row class="different-category-row">
                        <v-col cols="4">
                            <qrcode-vue :value="order.qrcode.pickUpQRCode" :size="size" level="H" />
                        </v-col>
                        <v-col cols="4">
                            <p class="ma-0">{{ order.qrcode.pickUpPassword }}</p>
                        </v-col>
                        <v-col cols="2">
                            <p class="ma-0">{{ order.qrcode.locker_seat }}</p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
export default {
    name: "PickOutQrcodeDialog",

    components: {
        QrcodeVue,
    },
    props: {
        order: Object,
    },
    data() {
        return {
            // value: "https://example.com",
            size: 100,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.v-chip {
    margin-left: 5px;
    margin-right: 5px;
}

.container {
    border: 2px solid #000000;
}

.col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 4px;
    /* border: 1px solid #000000; */
}

.v-btn {
    margin: 5px;
}

.same-category-each-cell {
    border-right: 1px solid #efefef;
}

.container-header {
    background-color: #dedede;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}

.different-category-row {
    border-bottom: 1px solid #000000;
}
</style>
