<template>
    <div>
        <ordererVisitorAppBar></ordererVisitorAppBar>
        <h1 class="mt-10">文件申請系統-校友註冊</h1>
        <v-form v-model="isFormValid" @submit.prevent="isFormValid && register()">
            <v-container>
                <v-row class="each-item-row">
                    <v-col cols="3" align-self="center">
                        <span>身分證字號</span>
                        <span style="color: red">*</span>
                    </v-col>
                    <v-col>
                        <v-row>
                            <v-col class="py-0">
                                <v-radio-group v-model="personalIdTypeSelected" dense hide-details="auto" class="mt-0 pt-0">
                                    <v-radio v-for="(personalIdType, index) in personalIdTypeOptions" :key="index" :label="personalIdType" :value="personalIdType"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="personalId" :rules="personalIdRules" outlined dense hide-details="auto" required maxlength="10"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="each-item-row">
                    <v-col cols="3" align-self="center">
                        <span>生日</span>
                        <span style="color: red">*</span>
                    </v-col>
                    <v-col>
                        <v-menu v-model="birthdayShow" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="50">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="birthday" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense hide-details="auto" style="width: 150px"></v-text-field>
                            </template>
                            <v-date-picker v-model="birthday" @input="birthdayShow = false" :max="today"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row class="each-item-row">
                    <v-col cols="3" align-self="center">
                        <span>密碼</span>
                        <span style="color: red">*</span>
                    </v-col>
                    <v-col>
                        <v-text-field type="password" v-model="password" :rules="passwordRules" outlined required hide-details="auto" dense placeholder="密碼"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="each-item-row">
                    <v-col cols="3" align-self="center">
                        <span>再次輸入密碼</span>
                        <span style="color: red">*</span>
                    </v-col>
                    <v-col>
                        <v-text-field type="password" v-model="checkPassword" :rules="checkPasswordRules" outlined required hide-details="auto" dense placeholder="再次輸入密碼"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn type="submit" color="#00BFFF" :loading="submitButtonLoading">註冊</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
import api from "../api/api.js";
import ordererVisitorAppBar from "../components/OrdererVisitorAppBar.vue";

export default {
    name: "Register",

    components: {
        ordererVisitorAppBar,
    },

    data() {
        return {
            isFormValid: false,
            password: "",
            checkPassword: "",
            personalId: "",
            personalIdTypeOptions: ["身分證字號", "居留證號碼"],
            personalIdTypeSelected: "身分證字號",
            passwordRules: [v => !!v || "密碼不得為空"],
            checkPasswordRules: [v => !!v || "密碼不得為空", v => v === this.password || "密碼錯誤"],
            personalIdRules: [v => !!v || "身分證字號不得為空", v => /^[A-Z]{1}[1289A-D]{1}[0-9]{8}$/.test(v) || "身分證字號格式錯誤"],
            today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            birthday: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            birthdayShow: false,
            submitButtonLoading: false,
            isCheckingTokenValid: true,
        };
    },

    methods: {
        verifyPersonalId() {
            if (this.personalId.length === 0) {
                return;
            }

            let englishSerial = "ABCDEFGHJKLMNPQRSTUVXYWZIO";
            let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];
            let newPersonalId = "";
            let sum = 0;
            let verifyNumber = 0;

            //如果是身分證或新式居留證
            if (this.personalId[1].charCodeAt() >= 48 && this.personalId[1].charCodeAt() <= 57) {
                newPersonalId = String(englishSerial.indexOf(this.personalId[0]) + 10) + this.personalId.slice(1);
            } else {
                newPersonalId = String(englishSerial.indexOf(this.personalId[0]) + 10) + this.personalId.slice(1);
                newPersonalId = newPersonalId.slice(0, 2) + String(englishSerial.indexOf(newPersonalId[2]) % 10) + newPersonalId.slice(3);
            }

            for (let i = 0; i < newPersonalId.length; i++) {
                sum += weights[i] * parseInt(newPersonalId[i]);
            }

            verifyNumber = sum % 10;
            return verifyNumber === 0 ? true : false;
        },

        register() {
            if (!this.verifyPersonalId()) {
                this.$toasted.show("身分證格式錯誤，請重新輸入。", {
                    type: "error",
                    duration: 2000,
                });
                return;
            }

            this.submitButtonLoading = true;

            api.register({
                personal_id: this.personalId,
                password: this.password,
                birthday: this.birthday,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.$toasted.show("註冊成功，請重新登入。", {
                            type: "success",
                            duration: 2000,
                        });
                        this.$router.replace("/login");
                    } else {
                        this.$toasted.show("註冊失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("註冊失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },
    },
};
</script>

<style scoped>
.brand-img {
    width: 300px;
}

.container {
    border: 1px solid #000000;
    max-width: 600px;
    min-width: 300px;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}

@media screen and (max-width: 600px) {
    .brand-img {
        width: 50%;
    }
}
</style>
