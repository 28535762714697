<template>
    <div>
        <v-dialog v-model="isShowDialog">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" @click="isEdit = true">編輯</v-btn>
            </template>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> 編輯文件 </v-card-title>
                <v-container>
                    <v-row class="container-header each-item-row">
                        <v-col cols="4" class="same-category-each-cell">文件名稱</v-col>
                        <v-col cols="4" class="same-category-each-cell">ecnt</v-col>
                        <v-col cols="2" class="same-category-each-cell">會計科目</v-col>
                        <v-col cols="2">金額</v-col>
                    </v-row>
                    <v-row class="different-category-row">
                        <v-col cols="4">
                            <p v-if="!isEdit" class="ma-0">{{ document.name }}</p>
                            <v-text-field v-if="isEdit" v-model="nameTemp" outlined hide-details="auto" dense maxlength="100"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <p v-if="!isEdit" class="ma-0">{{ document.ecnt }}</p>
                            <v-text-field v-if="isEdit" v-model="ecntTemp" outlined hide-details="auto" dense maxlength="200"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <p v-if="!isEdit" class="ma-0">{{ document.account.number }}</p>
                            <v-select v-if="isEdit" v-model="accountIdTemp" :items="accounts" item-text="number" item-value="id" outlined hide-details="auto" dense></v-select>
                        </v-col>
                        <v-col cols="2">
                            <p v-if="!isEdit" class="ma-0">$ {{ document.price }}</p>
                            <v-text-field v-if="isEdit" type="number" v-model.number="priceTemp" outlined hide-details="auto" dense min="0" max="9999"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="container-header each-item-row">
                        <v-col cols="1" class="same-category-each-cell">顯示</v-col>
                        <v-col cols="1" class="same-category-each-cell">開放備註</v-col>
                        <v-col cols="1" class="same-category-each-cell">學期選擇</v-col>
                        <v-col cols="1" class="same-category-each-cell">實體</v-col>
                        <v-col cols="1" class="same-category-each-cell">在校生申請</v-col>
                        <v-col cols="1" class="same-category-each-cell">非在校生申請</v-col>
                        <v-col cols="1" class="same-category-each-cell">依學生校區</v-col>
                        <v-col cols="1" class="same-category-each-cell">選擇承辦處室</v-col>
                        <v-col cols="2" class="same-category-each-cell">備註</v-col>
                        <v-col cols="2">操作</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="1">
                            <p v-if="!isEdit" class="ma-0">{{ document.valid === true ? "是" : "否" }}</p>
                            <v-checkbox v-if="isEdit" v-model="validTemp" hide-details="auto" dense></v-checkbox>
                        </v-col>
                        <v-col cols="1">
                            <p v-if="!isEdit" class="ma-0">{{ document.remark_valid === true ? "是" : "否" }}</p>
                            <v-checkbox v-if="isEdit" v-model="remarkValidTemp" hide-details="auto" dense></v-checkbox>
                        </v-col>
                        <v-col cols="1">
                            <p v-if="!isEdit" class="ma-0">{{ document.semester_require === true ? "是" : "否" }}</p>
                            <v-checkbox v-if="isEdit" v-model="semesterRequireTemp" hide-details="auto" dense></v-checkbox>
                        </v-col>
                        <v-col cols="1">
                            <p v-if="!isEdit" class="ma-0">{{ document.is_entity === true ? "是" : "否" }}</p>
                            <v-checkbox v-if="isEdit" v-model="isEntityTemp" hide-details="auto" dense></v-checkbox>
                        </v-col>
                        <v-col cols="1">
                            <p v-if="!isEdit" class="ma-0">{{ document.is_for_member === true ? "是" : "否" }}</p>
                            <v-checkbox v-if="isEdit" v-model="isForMemberTemp" hide-details="auto" dense></v-checkbox>
                        </v-col>
                        <v-col cols="1">
                            <p v-if="!isEdit" class="ma-0">{{ document.is_for_alumni === true ? "是" : "否" }}</p>
                            <v-checkbox v-if="isEdit" v-model="isForAlumniTemp" hide-details="auto" dense></v-checkbox>
                        </v-col>
                        <v-col cols="1">
                            <p v-if="!isEdit" class="ma-0">{{ document.change_area === true ? "是" : "否" }}</p>
                            <v-checkbox v-if="isEdit" v-model="changeArea" hide-details="auto" dense></v-checkbox>
                        </v-col>
                        <v-col cols="1">
                            <p v-if="!isEdit" class="ma-0">{{ document.department_assign }}</p>
                            <v-select v-if="isEdit" v-model="admindepartsTemp" :items="admindeparts" label="document.department_assign" dense solo hide-details></v-select>
                        </v-col>
                        <v-col cols="2">
                            <p v-if="!isEdit" class="ma-0">{{ document.remark }}</p>
                            <v-text-field v-if="isEdit" v-model="remarkTemp" outlined hide-details="auto" dense></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <!-- <v-btn v-if="pageActions.data_setting_document_edit" @click="isEdit = true" color="primary" :disabled="isEdit">修改</v-btn> -->
                            <v-dialog v-model="isShowDeleteConfirmDialog" width="70%">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-if="pageActions.data_setting_document_delete" color="error" v-bind="attrs" v-on="on"> 刪除 </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="text-h5 grey lighten-2"> 確定要刪除此文件嗎? </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="deleteDocument" :loading="submitButtonLoading"> 刪除 </v-btn>
                                        <v-btn @click="isShowDeleteConfirmDialog = false" :disabled="submitButtonLoading"> 取消 </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <!-- <v-btn v-if="pageActions.data_setting_document_edit" @click="resetValue" :disabled="!isEdit || submitButtonLoading">取消</v-btn> -->
                            <v-btn v-if="pageActions.data_setting_document_edit" @click="updateDocument" color="success" :disabled="!isEdit" :loading="submitButtonLoading">儲存</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "../api/api.js";

export default {
    name: "ModifyDocumentDialog",

    props: {
        pageActions: Object,
        documentClass: Object,
        document: Object,
        accounts: Array,
    },

    data() {
        return {
            isShowDialog: false,
            isEdit: false,
            isShowDeleteConfirmDialog: false,
            nameTemp: "",
            ecntTemp: "",
            accountIdTemp: 0,
            priceTemp: 0,
            isForMemberTemp: false,
            isForAlumniTemp: false,
            isEntityTemp: false,
            changeArea: false,
            validTemp: false,
            remarkTemp: "",
            admindepartsTemp: "",
            remarkValidTemp: false,
            semesterRequireTemp: false,
            submitButtonLoading: false,
            admindeparts: [],
        };
    },

    watch: {
        isShowDialog(val) {
            if (val === false) {
                this.resetValue();
            }
        },
    },

    methods: {
        updateDocument() {
            this.isEdit = false;
            this.submitButtonLoading = true;
            console.log(this.admindepartsTemp);
            api.updateDocument({
                id: this.document.id,
                name: this.nameTemp,
                ecnt: this.ecntTemp,
                account_id: this.accountIdTemp,
                price: this.priceTemp,
                valid: this.validTemp,
                is_for_member: this.isForMemberTemp,
                is_for_alumni: this.isForAlumniTemp,
                is_entity: this.isEntityTemp,
                change_area: this.changeArea,
                remark: this.remarkTemp,
                department_assign: this.admindepartsTemp,
                remark_valid: this.remarkValidTemp,
                semester_require: this.semesterRequire,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.document.name = this.nameTemp;
                        this.document.ecnt = this.ecntTemp;
                        this.document.account =
                            this.accounts[
                                this.accounts.findIndex(account => {
                                    return account.id === this.accountIdTemp;
                                })
                            ];
                        this.document.price = this.priceTemp;
                        this.document.valid = this.validTemp;
                        this.document.is_for_member = this.isForMemberTemp;
                        this.document.is_for_alumni = this.isForAlumniTemp;
                        this.document.is_entity = this.isEntityTemp;
                        this.document.change_area = this.changeArea;
                        this.document.department_assign = this.admindepartsTemp;
                        this.document.remark = this.remarkTemp;
                        this.document.remark_valid = this.remarkValidTemp;
                        this.document.semester_require = this.semesterRequire;

                        this.$toasted.show("修改成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("修改失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },

        deleteDocument() {
            this.submitButtonLoading = true;

            api.deleteDocument({
                id: this.document.id,
            })
                .then(response => {
                    if (response.status === 202) {
                        this.isShowDeleteConfirmDialog = false;
                        this.isShowDialog = false;
                        this.documentClass.document.splice(this.documentClass.document.indexOf(this.document), 1);

                        this.$toasted.show("刪除成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("修改失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("修改失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },
        getAdminDepartmentsGroup() {
            api.getAdminDepartmentsGroup()
                .then(response => {
                    this.admindeparts = response.data;
                    console.log(this.admindeparts);
                })
                .catch(() => {
                    this.$toasted.show("新增失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },
        resetValue() {
            this.isEdit = false;
            this.nameTemp = this.document.name;
            this.ecntTemp = this.document.ecnt;
            this.accountIdTemp = this.document.account.id;
            this.priceTemp = this.document.price;
            this.validTemp = this.document.valid;
            this.isForMemberTemp = this.document.is_for_member;
            this.isForAlumniTemp = this.document.is_for_alumni;
            this.isEntityTemp = this.document.is_entity;
            this.changeArea = this.document.change_area;
            this.remarkTemp = this.document.remark;
            this.admindepartsTemp = this.document.department_assign;
            this.remarkValidTemp = this.document.remark_valid;
            this.semesterRequire = this.document.semester_require;
        },
    },

    mounted() {
        this.resetValue();
        this.getAdminDepartmentsGroup();
    },
};
</script>

<style scoped>
.v-chip {
    margin-left: 5px;
    margin-right: 5px;
}

.container {
    border: 2px solid #000000;
}

.col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 4px;
}

.v-btn {
    margin: 5px;
}

.same-category-each-cell {
    border-right: 1px solid #efefef;
}

.container-header {
    background-color: #dedede;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}

.different-category-row {
    border-bottom: 2px solid #000000;
}
</style>
