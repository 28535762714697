<template>
    <div>
        <v-dialog v-model="isShowDialog">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="success" v-bind="attrs" v-on="on"> 新增分類 </v-btn>
            </template>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> 新增文件分類 </v-card-title>
                <v-container>
                    <v-form ref="form" v-model="isFormValid" @submit.prevent="isFormValid && createDocumentClass()">
                        <v-row class="container-header each-item-row">
                            <v-col cols="6" class="same-category-each-cell">文件分類</v-col>
                            <v-col cols="3" class="same-category-each-cell">顯示</v-col>
                            <v-col cols="3">操作</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="name" type="text" :rules="nameRules" outlined hide-details="auto" dense required autofocus maxlength="20"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-checkbox v-model="valid" hide-details="auto" dense></v-checkbox>
                            </v-col>
                            <v-col cols="3">
                                <v-btn type="submit" color="success" :loading="submitButtonLoading">新增</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "../api/api.js";

export default {
    name: "CreateDocumentClassDialog",

    data() {
        return {
            isShowDialog: false,
            isFormValid: false,
            name: "",
            valid: false,
            nameRules: [v => !!v || "必填"],
            submitButtonLoading: false,
        };
    },

    watch: {
        isShowDialog(val) {
            if (val === false) {
                this.resetForm();
            }
        },
    },

    methods: {
        createDocumentClass() {
            this.submitButtonLoading = true;

            api.createDocumentClass({
                name: this.name,
                valid: this.valid,
            })
                .then(response => {
                    if (response.status === 201) {
                        this.isShowDialog = false;
                        this.resetForm();
                        this.$emit("getDocumentClasses");

                        this.$toasted.show("新增成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("新增失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("新增失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },

        resetForm() {
            this.$refs.form.resetValidation();
            this.name = "";
            this.valid = false;
        },
    },
};
</script>

<style scoped>
.container {
    border: 2px solid #000000;
}

.col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.same-category-each-cell {
    border-right: 1px solid #efefef;
}

.container-header {
    background-color: #dedede;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}
</style>
