<template>
    <div>
        <img src="https://www.nkust.edu.tw/var/file/0/1000/img/513/539900619.png" width="300" />
        <h1>資料申請系統</h1>
        <div>
            <p>付款失敗，請重新付款!</p>
            <p>5秒後將自動幫您導回首頁</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "LinePayPaymentsCancel",

    data() {
        return {};
    },

    methods: {},

    mounted() {
        setTimeout(() => {
            this.$router.replace("/application-record/");
        }, 1000 * 5);
    },
};
</script>

<style scoped>
body {
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
}
</style>
