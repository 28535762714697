<template>
    <div>
        <adminAppBar></adminAppBar>
        <v-container v-if="!isMobile" class="mt-10">
            <v-row class="each-item-row container-header">
                <v-col cols="1" class="same-category-each-cell">學號</v-col>
                <v-col cols="2" class="same-category-each-cell">付款方式</v-col>
                <v-col cols="1" class="same-category-each-cell">案件狀態</v-col>
                <v-col cols="1" class="same-category-each-cell">付款狀態</v-col>
                <v-col cols="2" class="same-category-each-cell">取貨方式</v-col>
                <v-col cols="1" class="same-category-each-cell">選擇校區</v-col>
                <v-col cols="4" class="same-category-each-cell">日期區間</v-col>
            </v-row>
            <v-row>
                <v-col cols="1">
                    <v-text-field v-model="account" autofocus outlined dense hide-details="auto" maxlength="10"></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-select v-model="paymentId" :items="payments" item-text="name" item-value="id" outlined dense hide-details="auto"></v-select>
                </v-col>
                <v-col cols="1">
                    <v-select v-model="orderStatus" :items="statusItem" item-text="status" item-value="id" outlined dense hide-details="auto"></v-select>
                </v-col>
                <v-col cols="1">
                    <v-select v-model="paymentStatusId" :items="paymentStatusEnum" item-text="paymentStatus" item-value="id" outlined dense hide-details="auto"></v-select>
                </v-col>
                <v-col cols="2">
                    <v-select v-model="deliveryId" :items="deliveries" item-text="name" item-value="id" outlined dense hide-details="auto"></v-select>
                </v-col>
                <v-col cols="1">
                    <v-select v-model="areaId" :items="adminArea" item-text="name" item-value="id" outlined dense hide-details="auto"></v-select>
                </v-col>
                <v-col cols="4">
                    <v-menu v-model="dateStartShow" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="50">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateStartAt" label="選擇起始時間" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense hide-details="auto" style="width: 150px" class="mt-3"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateStartAt" @input="dateStartShow = false" :max="today"></v-date-picker>
                    </v-menu>
                    ~
                    <v-menu v-model="dateEndShow" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateEndAt" label="選擇結束時間" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense hide-details="auto" style="width: 150px" class="mt-3"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateEndAt" @input="dateEndShow = false" :max="today"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn v-if="pageActions.order_management_search" @click="getOrders" :loading="isSearchingOrders">搜尋</v-btn>
                    <v-btn v-if="pageActions.order_management_export_excel" :disabled="!orders.length" :loading="isExportingExcel">
                        <jsonExcel :data="orders" :fields="excelFields" :name="excelFileName" stringifyLongNum :before-generate="startDownloadExcel" :before-finish="finishDownloadExcel">匯出Excel</jsonExcel>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-if="isMobile">
            <v-row class="different-category-row">
                <v-col cols="3" class="each-item-column">學號</v-col>
                <v-col>
                    <v-text-field v-model="account" autofocus outlined dense hide-details="auto"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="different-category-row">
                <v-col cols="3" class="each-item-column">付款方式</v-col>
                <v-col>
                    <v-select v-model="paymentId" :items="payments" item-text="name" item-value="id" outlined dense hide-details="auto"></v-select>
                </v-col>
            </v-row>
            <v-row class="different-category-row">
                <v-col cols="3" class="each-item-column">案件狀態</v-col>
                <v-col>
                    <v-select v-model="orderStatus" :items="statusItem" item-text="status" :item-value="id" outlined dense hide-details="auto"></v-select>
                </v-col>
            </v-row>
            <v-row class="different-category-row">
                <v-col cols="3" class="each-item-column">付款狀態</v-col>
                <v-col>
                    <v-select v-model="paymentStatusId" :items="paymentStatusEnum" item-text="paymentStatus" item-value="id" outlined dense hide-details="auto"></v-select>
                </v-col>
            </v-row>
            <v-row class="different-category-row">
                <v-col cols="3" class="each-item-column">取貨方式</v-col>
                <v-col>
                    <v-select v-model="deliveryId" :items="deliveries" item-text="name" item-value="id" outlined dense hide-details="auto"></v-select>
                </v-col>
            </v-row>
            <v-row class="different-category-row">
                <v-col cols="3" class="each-item-column">選擇校區</v-col>
                <v-col>
                    <v-select v-model="deliveryId" :items="deliveries" item-text="name" item-value="id" outlined dense hide-details="auto"></v-select>
                </v-col>
            </v-row>
            <v-row class="different-category-row">
                <v-col cols="3" class="each-item-column">日期</v-col>
                <v-col cols="9">
                    <v-menu v-model="dateStartShow" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="50">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateStartAt" label="選擇起始時間" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense hide-details="auto" style="width: 150px"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateStartAt" @input="dateStartShow = false" :max="today"></v-date-picker>
                    </v-menu>
                    ~
                    <v-menu v-model="dateEndShow" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateEndAt" label="選擇結束時間" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense hide-details="auto" style="width: 150px"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateEndAt" @input="dateEndShow = false" :max="today"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn v-if="pageActions.order_management_search" @click="getOrders" :loading="isSearchingOrders">搜尋</v-btn>
                    <v-btn v-if="pageActions.order_management_export_excel" :disabled="!orders.length" :loading="isExportingExcel">
                        <jsonExcel :data="orders" :fields="excelFields" :name="excelFileName" stringifyLongNum :before-generate="startDownloadExcel" :before-finish="finishDownloadExcel">匯出Excel</jsonExcel>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-data-table :headers="headers" :items="orders" :items-per-page="5" :loading="isLoading">
            <template v-slot:item.paid="{ item }">
                {{ enumPaidStatus(item.paid) }}
            </template>
            <template v-slot:item.control="{ item }">
                <v-row class="justify-center" no-gutters>
                    <v-col cols="2" class="mr-1 ml-2">
                        <managementOrderDialog v-if="item.result == 0 || item.result == 2" :pageActions="pageActions" :order="item" :progresses="progresses" @getOrders="nodategetOrders"></managementOrderDialog>
                    </v-col>
                    <v-col v-if="pageActions.admin_setting_order_allocation" class="d-flex" cols="4" sm="4">
                        <v-select v-model="item.updateadminname" :items="adminname" label="承辦人" dense solo hide-details></v-select>
                    </v-col>
                    <v-col v-if="pageActions.admin_setting_order_allocation" class="mr-3 ml-2" cols="2" sm="3">
                        <v-btn @click="createAssign(item)" color="primary"> 送出 </v-btn>
                    </v-col>
                </v-row>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import api from "../api/api.js";
import adminAppBar from "../components/AdminAppBar.vue";
import managementOrderDialog from "../components/ManagementOrderDialog.vue";
import JsonExcel from "vue-json-excel";

export default {
    name: "ManagementOrder",

    components: {
        adminAppBar,
        managementOrderDialog,
        JsonExcel,
    },

    data() {
        return {
            isMobile: [],
            account: "",
            payments: [{ id: 0, name: "全部" }],
            isGetPayments: false,
            paymentId: 0,
            paymentStatusEnum: [
                { id: 2, paymentStatus: "全部" },
                { id: 0, paymentStatus: "未付款" },
                { id: 1, paymentStatus: "已付款" },
            ],
            statusItem: [
                { id: 0, status: "尚未結案" },
                { id: 1, status: "結案" },
                { id: 2, status: "撤案" },
            ],
            orderStatus: 0,
            paymentStatusId: 2,
            deliveries: [{ id: 0, name: "全部" }],
            isGetdeliveries: false,
            deliveryId: 0,
            areaId: 0,
            adminArea: [{ id: 0, name: "全部" }],
            today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            dateStartAt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            dateStartShow: false,
            dateEndAt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            dateEndShow: false,
            orders: [],
            progresses: [],
            isGetProgresses: false,
            isSearchingOrders: false,
            isAssignEvent: false,
            isExportingExcel: false,
            headers: [
                { text: "訂單編號", value: "uuid", align: "center" },
                { text: "學號", value: "user.account", align: "center" },
                { text: "姓名", value: "user.name", align: "center" },
                { text: "取貨方式", value: "delivery", align: "center" },
                { text: "付款方式", value: "payment", align: "center" },
                { text: "付款狀態", value: "paid", align: "center" },
                { text: "承辦人", value: "admin", align: "center" },
                { text: "操作", value: "control", align: "center", width: 400 },
            ],
            excelFileName: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10) + "資料申請系統訂單.xls",
            excelFields: {
                訂單編號: "uuid",
                姓名: "user.name",
                學號: "user.account",
                訂單內容: {
                    field: "order_detail",
                    callback: value => {
                        let orderDetail = "";
                        value.forEach(function (element, index) {
                            if (index !== value.length - 1) {
                                orderDetail += element.document.document_name + "x" + element.amount + "\n";
                            } else {
                                orderDetail += element.document.document_name + "x" + element.amount;
                            }
                        });
                        return orderDetail;
                    },
                },
                繳費日期: "paid_time",
                金額: "price",
                運費: "delivery_fee",
                總金額: "total_price",
                取貨方式: "delivery.delivery",
                付款方式: "payment.payment",
                LinePay綁定方式: "linepay_payment.method",
                付款狀態: {
                    field: "paid",
                    callback: value => {
                        return value === true ? "已付款" : "未付款";
                    },
                },
                申請時間: "create_date",
            },
            pageActions: {},
            pageActionList: ["order_management_search", "order_management_export_excel", "order_management_update_order_state", "admin_setting_admin_group_add", "admin_setting_admin_group_edit", "admin_setting_admin_group_delete", "admin_setting_admin_add", "admin_setting_admin_delete", "admin_setting_order_allocation"],
            getAdminGroup: [],
            adminname: [],
            updateadminname: "",
        };
    },

    computed: {
        isLoading() {
            return !(this.isGetPayments && this.isGetdeliveries && !this.isSearchingOrders && this.isGetProgresses && !this.isAssignEvent);
        },
    },

    mounted() {
        this.checkKeyIsValid("order_management");
        this.checkKeyIsValidForComponent();
        this.getAdminGroups();
        this.getAdminAreaRight();
        if (this.checkTokenIsValid() === true) {
            this.isMobile = this.checkIsMobile();
            this.getDeliveries();
            this.getPayments();
            this.getProgresses();
        } else {
            this.$router.push("/admin");
        }
        this.nodategetOrders();
    },

    methods: {
        getDeliveries() {
            api.getDeliveries()
                .then(response => {
                    if (response.status === 200) {
                        this.deliveries = this.deliveries.concat(response.data);
                        this.isGetdeliveries = true;
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        getPayments() {
            api.getPayments()
                .then(response => {
                    if (response.status === 200) {
                        this.payments = this.payments.concat(response.data);
                        this.isGetPayments = true;
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        getProgresses() {
            api.getProgresses()
                .then(response => {
                    if (response.status === 200) {
                        let progressesTemp = response.data;
                        console.log(progressesTemp);
                        for (let i = 1; i < progressesTemp.length; i++) {
                            if (progressesTemp[i].valid === true) {
                                this.progresses.push(progressesTemp[i]);
                            }
                        }
                        this.isGetProgresses = true;
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },
        getOrders() {
            this.isSearchingOrders = true;
            console.log(this.orders);
            api.getOrders({
                params: {
                    type: "admin",
                    account: this.account,
                    payment_id: this.paymentId === 0 ? null : this.paymentId,
                    paymentStatus: this.paymentStatusId === 2 ? null : String(this.paymentStatusId),
                    delivery_id: this.deliveryId === 0 ? null : this.deliveryId,
                    area_id: this.areaId == 0 ? null : this.adminArea[this.areaId].id,
                    orderStatus: this.orderStatus === 0 ? null : this.orderStatus,
                    dateStartAt: this.dateStartAt,
                    dateEndAt: this.dateEndAt,
                },
            })
                .then(response => {
                    if (response.status === 200) {
                        this.orders = response.data;
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isSearchingOrders = false;
                });
            // this.getEvent();
        },

        nodategetOrders() {
            this.isSearchingOrders = true;
            api.getOrders({
                params: {
                    type: "admin",
                    account: this.account,
                    payment_id: this.paymentId === 0 ? null : this.paymentId,
                    paymentStatus: this.paymentStatusId === 2 ? null : this.paymentStatusId,
                    delivery_id: this.deliveryId === 0 ? null : this.deliveryId,
                    dateStartAt: null,
                    dateEndAt: null,
                },
            })
                .then(response => {
                    if (response.status === 200) {
                        this.orders = response.data;
                        console.log(this.orders);
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isSearchingOrders = false;
                });
            // this.getEvent();
        },

        updateEvent(item) {
            this.submitButtonLoading = true;
            api.updateEvent({
                uuid: item.uuid,
                name: item.updateadminname,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.$toasted.show("修改成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("修改失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },
        getAdminGroups() {
            api.getAdminGroups()
                .then(response => {
                    if (response.status === 200) {
                        this.adminGroups = response.data;
                        // let adminuser = this.adminGroups[1].user_admin_group;
                        for (let i = 0; i < Object.keys(this.adminGroups).length; i++) {
                            let adminuser = this.adminGroups[i].user_admin_group;
                            for (let j = 0; j < Object.keys(adminuser).length; j++) {
                                this.adminname.push({ text: adminuser[j].user.name, value: adminuser[j].user_id });
                            }
                        }
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetAdminGroups = true;
                });
        },

        createAssign(item) {
            this.submitButtonLoading = true;
            api.createAssign({
                orderid: item.id,
                userid: item.updateadminname,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.nodategetOrders();
                        this.$toasted.show("指派成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("指派失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("指派失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },
        getAdminAreaRight() {
            api.getAdminAreaRight({})
                .then(response => {
                    if (response.status === 200) {
                        this.adminArea = this.adminArea.concat(response.data);
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        startDownloadExcel() {
            this.isExportingExcel = true;
        },

        finishDownloadExcel() {
            this.isExportingExcel = false;
        },

        enumPaidStatus(paid) {
            return paid === true ? "已付款" : "未付款";
        },

        checkIsMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            return flag;
        },

        checkKeyIsValidForComponent() {
            let key = this.$cookies.get("key");
            console.log(this.$cookies);
            this.pageActionList.forEach(element => {
                if (key.indexOf(element) == -1) {
                    this.$set(this.pageActions, element, false);
                } else {
                    this.$set(this.pageActions, element, true);
                }
            });
        },

        checkKeyIsValid(keyTarget) {
            let key = this.$cookies.get("key");

            if (key.indexOf(keyTarget) == -1) {
                alert("您無權限。");
                this.$router.go(-1);
            }
        },

        checkTokenIsValid() {
            let token = this.$cookies.get("Authorization");

            if (!token) {
                this.isCheckingTokenValid = false;
                return false;
            }

            return true;
        },
    },
};
</script>

<style scoped>
.v-btn {
    margin: 5px;
}

.container {
    width: 100% !important;
    min-width: 1500px;
    margin-bottom: 50px;
    border: 2px solid #000000;
}

.v-data-table {
    width: 80%;
    min-width: 300px;
    margin: 0 auto;
    margin-bottom: 50px;
    border: 2px solid #000000;
}

.col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.same-category-each-cell {
    border-right: 1px solid #efefef;
}

.container-header {
    background-color: #dedede;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}

.each-item-column {
    border-right: 1px solid #000000;
}

.different-category-row {
    border-bottom: 2px solid #000000;
}

@media screen and (max-width: 1000px) {
    .brand-img {
        width: 50%;
    }

    .container {
        width: 100%;
    }

    .v-data-table {
        width: 100%;
    }
}
</style>
