<template>
    <div>
        <v-overlay :value="!isGetAdminGroups">
            <v-progress-circular :indeterminate="!isGetAdminGroups" color="primary"></v-progress-circular>
        </v-overlay>
        <adminAppBar></adminAppBar>
        <v-card v-for="(adminGroup, index) in adminGroups" :key="index" class="my-4">
            <v-card-title class="text-h6 blue lighten-3 justify-space-between">
                <span>{{ adminGroup.name }} </span>
                <div class="d-flex align-center">
                    <createUserAdminGroupDialog v-if="pageActions.admin_setting_admin_add" :adminGroup="adminGroup" @getAdminGroups="getAdminGroups"></createUserAdminGroupDialog>
                    <v-btn v-if="pageActions.admin_setting_order_area_setting" color="primary" @click="openAreaRightDialog(adminGroup)" small> <v-icon>mdi-map-marker</v-icon></v-btn>
                    <!-- <modifyAreaRightDialog v-if="pageActions.admin_setting_order_area_setting" ref="arearight" @getAdminGroups="getAdminGroups" @click="openAreaRightDialog(adminGroup)"></modifyAreaRightDialog> -->
                    <modifyAdminRightDialog v-if="pageActions.admin_setting_admin_group_edit" :adminGroup="adminGroup" :pages="pages" :isGetAdminGroups="isGetAdminGroups" @getAdminGroups="getAdminGroups"></modifyAdminRightDialog>
                    <deleteAdminGroupDialog v-if="pageActions.admin_setting_admin_group_delete" :adminGroup="adminGroup" @deleteAdminGroup="deleteAdminGroup"></deleteAdminGroupDialog>
                </div>
            </v-card-title>
            <v-container>
                <v-row class="container-header">
                    <v-col cols="4" class="same-category-each-cell">帳號</v-col>
                    <v-col cols="4" class="same-category-each-cell">姓名</v-col>
                    <v-col>操作</v-col>
                </v-row>
                <v-row v-for="(user, index) in adminGroup.user_admin_group" :key="index">
                    <v-col cols="4">{{ user.user.account }}</v-col>
                    <v-col cols="4">{{ user.user.name }}</v-col>
                    <v-col>
                        <deleteUserAdminGroupDialog v-if="pageActions.admin_setting_admin_delete" :userAdminGroup="user" @getAdminGroups="getAdminGroups"></deleteUserAdminGroupDialog>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <createAdminGroupDialog v-if="pageActions.admin_setting_admin_group_add" @getAdminGroups="getAdminGroups"></createAdminGroupDialog>
        <modifyAreaRightDialog ref="arearight"></modifyAreaRightDialog>
    </div>
</template>

<script>
import api from "../api/api.js";
import adminAppBar from "../components/AdminAppBar.vue";
import createAdminGroupDialog from "../components/CreateAdminGroupDialog.vue";
import createUserAdminGroupDialog from "../components/CreateUserAdminGroupDialog.vue";
import modifyAdminRightDialog from "../components/ModifyAdminRightDialog.vue";
import deleteAdminGroupDialog from "../components/DeleteAdminGroupDialog.vue";
import deleteUserAdminGroupDialog from "../components/DeleteUserAdminGroupDialog.vue";
import modifyAreaRightDialog from "../components/ModifyAreaRightDialog.vue";

export default {
    name: "ManagementAdmin",

    components: {
        adminAppBar,
        createAdminGroupDialog,
        createUserAdminGroupDialog,
        modifyAdminRightDialog,
        deleteAdminGroupDialog,
        deleteUserAdminGroupDialog,
        modifyAreaRightDialog,
    },

    mounted() {
        this.checkKeyIsValid("admin_setting");
        this.checkKeyIsValidForComponent();

        if (this.checkTokenIsValid() === true) {
            this.isMobile = this.checkIsMobile();
            this.getAdminGroups();
            this.getPages();
        } else {
            this.$router.push("/admin");
        }
    },

    data() {
        return {
            adminGroups: [],
            pages: [],
            isGetAdminGroups: false,
            isGetPages: false,
            pageActions: {},
            pageActionList: ["admin_setting_admin_group_add", "admin_setting_admin_group_edit", "admin_setting_admin_group_delete", "admin_setting_admin_add", "admin_setting_order_area_setting", "admin_setting_admin_delete"],
        };
    },

    methods: {
        getAdminGroups() {
            this.isGetAdminGroups = false;
            api.getAdminGroups()
                .then(response => {
                    if (response.status === 200) {
                        this.adminGroups = response.data;
                        // console.log(this.adminGroups);
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetAdminGroups = true;
                });
        },

        deleteAdminGroup(adminGroup) {
            this.adminGroups.splice(this.adminGroups.indexOf(adminGroup), 1);
        },

        getPages() {
            api.getPages()
                .then(response => {
                    if (response.status === 200) {
                        this.pages = response.data;
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetPages = true;
                });
        },

        openAreaRightDialog(adminGroup) {
            this.$refs.arearight.init(adminGroup);
        },
        checkIsMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            return flag;
        },

        checkKeyIsValidForComponent() {
            let key = this.$cookies.get("key");
            console.log(key);
            this.pageActionList.forEach(element => {
                console.log(element);
                if (key.indexOf(element) == -1) {
                    this.$set(this.pageActions, element, false);
                } else {
                    this.$set(this.pageActions, element, true);
                }
            });
        },

        checkKeyIsValid(keyTarget) {
            let key = this.$cookies.get("key");

            if (key.indexOf(keyTarget) == -1) {
                alert("您無權限。");
                this.$router.go(-1);
            }
        },

        checkTokenIsValid() {
            let token = this.$cookies.get("Authorization");

            if (!token) {
                this.isCheckingTokenValid = false;
                return false;
            }

            return true;
        },
    },
};
</script>

<style scoped>
.v-btn {
    margin-left: 4px;
    margin-right: 4px;
}

.v-card {
    margin: 0 auto;
    width: 50%;
    min-width: 450px;
}

.v-card__title {
    padding: 4px;
}

.col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.same-category-each-cell {
    border-right: 1px solid #efefef;
}

.container-header {
    background-color: #dedede;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}

.each-item-column {
    border-right: 1px solid #000000;
}

.different-category-row {
    border-bottom: 2px solid #000000;
}

@media screen and (max-width: 600px) {
    .brand-img {
        width: 50%;
    }

    .v-card {
        width: 100%;
        min-width: 250px;
    }
}
</style>
