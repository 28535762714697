<template>
    <div>
        <adminAppBar></adminAppBar>
        <v-container v-if="!isMobile" class="search-container mt-10">
            <v-row class="each-item-row container-header">
                <v-col cols="4" class="each-item-column">付款日期</v-col>
                <v-col cols="3" class="each-item-column">付款方式</v-col>
                <v-col cols="5">操作</v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="search-col">
                    <v-menu v-model="dateStartShow" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="50">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateStartAt" label="選擇起始時間" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense hide-details="auto" style="width: 150px"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateStartAt" @input="dateStartShow = false" :max="today"></v-date-picker>
                    </v-menu>
                    ~
                    <v-menu v-model="dateEndShow" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateEndAt" label="選擇結束時間" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense hide-details="auto" style="width: 150px"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateEndAt" @input="dateEndShow = false" :max="today"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="3">
                    <v-select v-model="paymentMethod" :items="paymentMethods" outlined dense hide-details="auto"></v-select>
                </v-col>
                <v-col cols="5">
                    <v-btn v-if="pageActions.financial_statement_searching" @click="getLinePayPayments" :loading="isSearchingLinePayPayments">搜尋</v-btn>
                    <v-btn v-if="pageActions.financial_statement_export_excel" :disabled="!linePayPayments.data.length" :loading="isExportingExcel" @click="createExcel" :href="url">
                        <!-- <jsonExcel :data="linePayPayments.data" :fields="excelFields" :name="excelFileName" stringifyLongNum :before-generate="startDownloadExcel" :before-finish="finishDownloadExcel">匯出Excel</jsonExcel> -->
                        匯出Excel
                    </v-btn>
                    <v-btn v-if="pageActions.financial_statement_print" @click="printFinancialStatement" :disabled="!linePayPayments.data.length">列印</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-if="isMobile"></v-container>
        <div class="date-and-payment">
            <span>付款日期：</span>
            <span style="display: inline-block; width: 200px">{{ dateToShow }}</span>
            <span>付款方式：</span>
            <span>{{ paymentMethodToShow }}</span>
        </div>
        <div ref="printId" id="printId" class="d-flex justify-center">
            <v-container class="financial-container" fluid>
                <v-row class="each-item-row">
                    <v-col style="min-width: 360px; max-width: 360px">
                        <v-row class="each-item-row">
                            <v-col class="each-item-column pa-1" style="height: 40px">會計科目</v-col>
                        </v-row>
                        <v-row class="each-item-row" style="height: 100px">
                            <v-col class="each-item-column pa-0" style="min-width: 50px; max-width: 50px">姓名</v-col>
                            <v-col class="each-item-column pa-0" style="min-width: 80px; max-width: 80px">學號/ID</v-col>
                            <v-col class="each-item-column pa-0" style="min-width: 90px; max-width: 90px">訂單編號</v-col>
                            <v-col class="each-item-column pa-0" style="min-width: 140px; max-width: 140px">LinePay付款交易序號</v-col>
                        </v-row>
                        <v-row v-for="(linePayPayment, index) in linePayPayments.data" :key="index" class="each-item-row">
                            <v-col class="each-item-column pa-0" style="min-width: 50px; max-width: 50px">{{ linePayPayment.order.user.name }}</v-col>
                            <v-col class="each-item-column pa-0" style="min-width: 80px; max-width: 80px; word-break: break-all">{{ linePayPayment.order.user.account }}</v-col>
                            <v-col class="each-item-column pa-0" style="min-width: 90px; max-width: 90px; word-break: break-all">{{ linePayPayment.order.uuid }}</v-col>
                            <v-col class="each-item-column pa-0" style="min-width: 140px; max-width: 140px; word-break: break-all">{{ linePayPayment.transaction_id }}</v-col>
                        </v-row>
                        <v-row class="each-item-row">
                            <v-col class="each-item-column pa-0" style="min-width: 365px; max-width: 365px">合計</v-col>
                        </v-row>
                    </v-col>
                    <v-col class="d-flex flex-nowrap each-item-column pa-0" style="overflow-x: scroll">
                        <v-col v-for="(account, index) in accounts" :key="index" :style="{ 'max-width': account.name === '郵資' ? '100px' : (account.document.length + 1) * 50 + 'px', 'min-width': account.name === '郵資' ? '100px' : (account.document.length + 1) * 50 + 'px' }">
                            <v-row class="each-item-row">
                                <v-col class="each-item-column pa-1" style="height: 40px" :style="{ 'font-size': account.number.length > 15 ? '6px' : '10px' }">{{ account.number }}</v-col>
                            </v-row>
                            <v-row class="each-item-row" style="height: 100px">
                                <v-col v-for="(document, indexOfDocument) in account.document" :key="indexOfDocument" class="each-item-column pa-0" style="min-width: 50px; max-width: 50px">{{ document.name }}</v-col>
                                <v-col v-if="account.name === '郵資'" class="each-item-column pa-0" style="max-width: 50px">{{ account.name }}</v-col>
                                <v-col class="each-item-column pa-0" style="max-width: 50px">{{ account.name + "小計" }}</v-col>
                            </v-row>
                            <v-row v-for="(linePayPayment, index) in linePayPayments.data" :key="index" class="each-item-row">
                                <v-col v-if="account.name === '郵資'" class="each-item-column pa-0" style="max-width: 50px">
                                    <span v-if="linePayPayment.order.delivery_fee > 0">{{ linePayPayment.order.delivery_fee }}</span>
                                </v-col>
                                <v-col v-for="(document, indexOfDocument) in account.document" :key="indexOfDocument" class="each-item-column pa-0">
                                    <div v-for="(orderDetail, indexOfOrderDetail) in linePayPayment.order.order_detail" :key="indexOfOrderDetail">
                                        <span v-if="document.id === orderDetail.document.id">{{ orderDetail.sub_total }}</span>
                                    </div>
                                </v-col>
                                <v-col class="each-item-column pa-0">0</v-col>
                            </v-row>
                            <v-row class="each-item-row">
                                <v-col v-if="account.name === '郵資'" class="each-item-column pa-0" style="max-width: 50px">0</v-col>
                                <v-col v-for="(document, indexOfDocument) in account.document" :key="indexOfDocument" class="each-item-column pa-0" style="min-width: 50px; max-width: 50px">0</v-col>
                                <v-col class="each-item-column pa-0" style="min-width: 50px; max-width: 50px">0</v-col>
                            </v-row>
                        </v-col>
                    </v-col>
                    <v-col style="max-width: 50px">
                        <v-row class="each-item-row">
                            <v-col class="pa-1" style="height: 140px">繳款金額</v-col>
                        </v-row>
                        <v-row v-for="(linePayPayment, index) in linePayPayments.data" :key="index" class="each-item-row">
                            <v-col class="pa-0">{{ linePayPayment.price }}</v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <!-- <v-row class="each-item-row">
                    <v-col class="each-item-column" style="max-width: 36%">會計科目</v-col>
                    <v-col class="each-item-column" style="max-width: 28%">420298-1001</v-col>
                    <v-col class="each-item-column" style="max-width: 8%">510301-2201</v-col>
                    <v-col class="each-item-column" style="max-width: 8%">420207-1005</v-col>
                    <v-col class="each-item-column" style="max-width: 8%">420298-1001、210203-1024</v-col>
                    <v-col class="each-item-column" style="max-width: 8%">210203-1024</v-col>
                    <v-col style="max-width: 4%">繳款金額</v-col>
                </v-row>
                <v-row class="each-item-row" style="overflow: scroll">
                    <v-col class="each-item-column" style="max-width: 36%; padding: 0px">
                        <v-col cols="2" class="cell each-item-column">姓名</v-col>
                        <v-col cols="3" class="cell each-item-column">學號/ID</v-col>
                        <v-col cols="3" class="cell each-item-column">訂單編號</v-col>
                        <v-col cols="4" class="cell">LinePay付款交易序號</v-col>
                    </v-col>
                    <v-col class="each-item-column" style="max-width: 28%; padding: 0px">
                        <v-col class="cell each-item-column">中文成績單</v-col>
                        <v-col class="cell each-item-column">英文在學證明</v-col>
                        <v-col class="cell each-item-column">畢業證書遺失補發</v-col>
                        <v-col class="cell each-item-column">英文成績單</v-col>
                        <v-col class="cell each-item-column">英文畢業證明書</v-col>
                        <v-col class="cell each-item-column">學生證補發</v-col>
                        <v-col class="cell sub-total-cell">工本費小計</v-col>
                    </v-col>
                    <v-col class="each-item-column" style="max-width: 8%; padding: 0px">
                        <v-col class="cell each-item-column">郵資</v-col>
                        <v-col class="cell sub-total-cell">郵資小計</v-col>
                    </v-col>
                    <v-col class="each-item-column" style="max-width: 8%; padding: 0px">
                        <v-col class="cell each-item-column">預約未搭交通車違規罰款</v-col>
                        <v-col class="cell sub-total-cell">預約未搭交通車違規罰款小計</v-col>
                    </v-col>
                    <v-col class="each-item-column" style="max-width: 8%; padding: 0px">
                        <v-col class="cell each-item-column">冷氣儲值卡製卡費及儲值費</v-col>
                        <v-col class="cell sub-total-cell">冷氣儲值卡製卡費及儲值費小計</v-col>
                    </v-col>
                    <v-col class="each-item-column" style="max-width: 8%; padding: 0px">
                        <v-col class="cell each-item-column">冷氣儲值費</v-col>
                        <v-col class="cell sub-total-cell">冷氣儲值費小計</v-col>
                    </v-col>
                </v-row>
                <v-row v-for="(linePayPayment, index) in linePayPayments.data" :key="index" class="each-item-row">
                    <v-col class="each-item-column" style="max-width: 36%; padding: 0px">
                        <v-col cols="2" class="cell each-item-column">{{ linePayPayment.order.user.name }}</v-col>
                        <v-col cols="3" class="cell each-item-column" style="word-break: break-all">{{ linePayPayment.order.user.account }}</v-col>
                        <v-col cols="3" class="cell each-item-column" style="word-break: break-all">{{ linePayPayment.order.uuid }}</v-col>
                        <v-col cols="4" class="cell" style="word-break: break-all">{{ linePayPayment.transaction_id }}</v-col>
                    </v-col>
                    <v-col class="each-item-column" style="max-width: 28%; padding: 0px">
                        <v-col class="cell each-item-column">{{ linePayPayment.order.chineseTranscript }}</v-col>
                        <v-col class="cell each-item-column">{{ linePayPayment.order.englishCertificateEnrollment }}</v-col>
                        <v-col class="cell each-item-column">{{ linePayPayment.order.chineseCertificateOfGraduation }}</v-col>
                        <v-col class="cell each-item-column">{{ linePayPayment.order.englishTranscript }}</v-col>
                        <v-col class="cell each-item-column">{{ linePayPayment.order.englishCertificateOfGraduation }}</v-col>
                        <v-col class="cell each-item-column">{{ linePayPayment.order.studentIdCard }}</v-col>
                        <v-col class="cell sub-total-cell">{{ linePayPayment.order.costSubtotal }}</v-col>
                    </v-col>
                    <v-col class="each-item-column" style="max-width: 8%; padding: 0px">
                        <v-col class="cell each-item-column">{{ linePayPayment.order.delivery_fee }}</v-col>
                        <v-col class="cell sub-total-cell">{{ linePayPayment.order.delivery_fee }}</v-col>
                    </v-col>
                    <v-col class="each-item-column" style="max-width: 8%; padding: 0px">
                        <v-col class="cell each-item-column">{{ linePayPayment.order.schoolBusFine }}</v-col>
                        <v-col class="cell sub-total-cell">{{ linePayPayment.order.schoolBusFine }}</v-col>
                    </v-col>
                    <v-col class="each-item-column" style="max-width: 8%; padding: 0px">
                        <v-col class="cell each-item-column">{{ linePayPayment.order.airConditionerCardFeeAndRechargeFee }}</v-col>
                        <v-col class="cell sub-total-cell">{{ linePayPayment.order.airConditionerCardFeeAndRechargeFee }}</v-col>
                    </v-col>
                    <v-col class="each-item-column" style="max-width: 8%; padding: 0px">
                        <v-col class="cell each-item-column">{{ linePayPayment.order.airConditionerCardRechargeFee }}</v-col>
                        <v-col class="cell sub-total-cell">{{ linePayPayment.order.airConditionerCardRechargeFee }}</v-col>
                    </v-col>
                    <v-col class="cell" style="max-width: 4%; padding: 0px">{{ linePayPayment.order.total_price }}</v-col>
                </v-row>
                <v-row class="each-item-row">
                    <v-col class="cell each-item-column justify-end" style="max-width: 36%">合計</v-col>
                    <v-col class="cell each-item-column" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column sub-total-cell" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column sub-total-cell" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column sub-total-cell" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column sub-total-cell" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column" style="max-width: 4%">0</v-col>
                    <v-col class="cell each-item-column sub-total-cell" style="max-width: 4%">0</v-col>
                    <v-col class="cell" style="max-width: 4%">{{ linePayPayments.allItemPrice }}</v-col>
                </v-row> -->
                <v-row class="each-item-row">
                    <v-col></v-col>
                </v-row>
                <v-row>
                    <v-col class="each-item-column justify-end">收款總計(A)</v-col>
                    <v-col class="each-item-column justify-end">{{ linePayPayments.allItemPrice }}</v-col>
                    <v-col class="each-item-column justify-end">手續費(B)(由當日最高收入支出)</v-col>
                    <v-col class="each-item-column justify-end">{{ linePayPayments.allLinePayFee }}</v-col>
                    <v-col class="each-item-column justify-end">入帳金額(A-B)</v-col>
                    <v-col class="justify-end">{{ linePayPayments.allReceivePrice }}</v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import api from "../api/api.js";
import adminAppBar from "../components/AdminAppBar.vue";
// import JsonExcel from "vue-json-excel";
import print from "vue-print-nb";
// import { ref } from "vue";
import { utils, writeFileXLSX } from "xlsx";
// const rows = ref([]);
// import XLSX from "xlsx/xlsx.mjs";
import html2canvas from "html2canvas";

export default {
    name: "FinancialStatement",

    components: {
        adminAppBar,
        // JsonExcel,
    },

    directives: {
        print,
    },

    data() {
        return {
            isCheckingTokenValid: false,
            isMobile: [],
            url: "",
            paymentMethods: [
                { text: "全部", value: "all" },
                { text: "LinePay", value: "LinePay" },
                { text: "LinePay Money", value: "LinePay Money" },
            ],
            paymentMethod: "all",
            paymentMethodToShow: "",
            today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            dateStartAt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            dateStartShow: false,
            dateEndAt: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            dateEndShow: false,
            dateToShow: "",
            linePayPayments: {
                data: [],
                allItemPrice: "",
                allDeliveryFee: "",
                allOrderPrice: "",
                allLinePayFee: "",
                allReceivePrice: "",
            },
            accounts: [],
            isSearchingLinePayPayments: false,
            isGetAccounts: false,
            excelFileName: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10) + "資料申請系統訂單.xls",
            excelFields: {
                會計科目: {
                    姓名: "order.user.name",
                    學號: "order.user.account",
                    訂單編號: "order.uuid",
                    LinePay付款交易序號: "transaction_id",
                },
                申請項目金額小計: "order.price",
                郵資: "order.delivery_fee",
                合計: "order.total_price",
                手續費: "fee",
                入帳金額: "receive_price",
            },
            isExportingExcel: false,
            printObject: {
                id: "print-data",
                popTitle: "",
                extraCss: "font-size: 10px",
            },
            pageActions: {},
            pageActionList: ["financial_statement_searching", "financial_statement_export_excel", "financial_statement_print"],
        };
    },

    mounted() {
        this.checkKeyIsValid("financial_statement");
        this.checkKeyIsValidForComponent();

        if (this.checkTokenIsValid() === true) {
            this.isMobile = this.checkIsMobile();
            this.getAccounts();
        } else {
            this.$router.push("/admin");
        }

        // const f = fetch("https://sheetjs.com/pres.numbers");
        // const ab = f.arrayBuffer();

        // /* parse workbook */
        // const wb = read(ab);

        // /* update data */
        // rows.value = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
    },

    methods: {
        getAccounts() {
            api.getAccounts()
                .then(response => {
                    if (response.status === 200) {
                        this.accounts = response.data;
                        console.log(this.accounts);
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetAccounts = true;
                });
        },

        createExcel() {
            api.createExcel({
                document: this.accounts,
                linePayPayments: this.linePayPayments,
            })
                .then(response => {
                    if (response.status === 200) {
                        // this.accounts = response.data;
                        console.log(response.data);
                        let createExcel = response.data;
                        // window.open(createExcel);
                        // window.location.href = createExcel;
                        this.url = createExcel;
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetAccounts = true;
                });
        },

        printFinancialStatement() {
            api.printFinancialStatement({
                document: this.accounts,
                linePayPayments: this.linePayPayments,
            })
                .then(response => {
                    if (response.status === 200) {
                        // this.accounts = response.data;
                        // console.log(response.data);
                        let ProofPaymentsURL = response.data;
                        window.open(ProofPaymentsURL);
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetAccounts = true;
                });
        },

        getLinePayPayments() {
            this.isSearchingLinePayPayments = true;

            api.getLinePayPayments({
                params: {
                    method: this.paymentMethod,
                    dateStartAt: this.dateStartAt,
                    dateEndAt: this.dateEndAt,
                },
            })
                .then(response => {
                    if (response.status === 200) {
                        console.log(this.paymentMethods);
                        this.linePayPayments = response.data;
                        this.dateToShow = this.dateStartAt + " ~ " + this.dateEndAt;
                        this.paymentMethodToShow = this.paymentMethods.filter(paymentMethod => paymentMethod.value === this.paymentMethod)[0].text;
                        this.printObject.popTitle = "付款日期：" + this.dateToShow + " 付款方式：" + this.paymentMethodToShow;
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isSearchingLinePayPayments = false;
                });
        },

        startDownloadExcel() {
            this.isExportingExcel = true;
        },

        finishDownloadExcel() {
            this.isExportingExcel = false;
        },

        checkIsMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            return flag;
        },

        checkKeyIsValidForComponent() {
            let key = this.$cookies.get("key");

            this.pageActionList.forEach(element => {
                if (key.indexOf(element) == -1) {
                    this.$set(this.pageActions, element, false);
                } else {
                    this.$set(this.pageActions, element, true);
                }
            });
        },

        checkKeyIsValid(keyTarget) {
            let key = this.$cookies.get("key");

            if (key.indexOf(keyTarget) == -1) {
                alert("您無權限。");
                this.$router.go(-1);
            }
        },

        checkTokenIsValid() {
            let token = this.$cookies.get("Authorization");

            if (!token) {
                this.isCheckingTokenValid = false;
                return false;
            }

            return true;
        },

        exportFile() {
            const ws = utils.json_to_sheet(this.accounts);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, "SheetJSVueAoO.xlsx");
        },

        onPrint() {
            const printContent = this.$refs.printId;
            // 獲取dom 寬度 高度
            const width = printContent.clientWidth;
            const height = printContent.clientHeight;
            // 建立一個canvas節點
            const canvas = document.createElement("canvas");

            const scale = 4; // 定義任意放大倍數，支援小數；越大，圖片清晰度越高，生成圖片越慢。
            canvas.width = width * scale; // 定義canvas 寬度 * 縮放
            canvas.height = height * scale; // 定義canvas高度 *縮放
            canvas.style.width = width * scale + "px";
            canvas.style.height = height * scale + "px";
            canvas.getContext("2d").scale(scale, scale); // 獲取context,設定scale

            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 獲取滾動軸滾動的長度
            const scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft; // 獲取水平滾動軸的長度

            html2canvas(printContent, {
                canvas,
                backgroundColor: null,
                useCORS: true,
                windowHeight: document.body.scrollHeight,
                scrollX: -scrollLeft, // 解決水平偏移問題，防止匯出的內容不全
                scrollY: -scrollTop,
            })
                .then(canvas => {
                    const url = canvas.toDataURL("image/png");
                    this.printJS({
                        printable: url,
                        type: "image",
                        documentTitle: "", // 標題
                        style: "@page{size:auto;margin: 0cm 1cm 0cm 1cm;}", // 去除頁首頁尾
                    });
                    window.open(url);
                })
                .catch(err => {
                    console.error(err);
                });
        },
    },
};
</script>

<style scoped>
.v-btn {
    margin: 5px;
}

.container {
    min-width: 300px;
    margin-bottom: 50px;
    border: 2px solid #000000;
}

.search-container {
    width: 80%;
}

.financial-container {
    width: 95%;
}

.col {
    font-size: 10px;
}

.search-col {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.date-and-payment {
    width: 85%;
    margin: 0 auto;
    text-align: center;
}

.container-header {
    background-color: #dedede;
}

.cell {
    height: 100%;
    padding: 0px;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}

.each-item-column {
    border-right: 1px solid #000000;
}

.sub-total-cell {
    background-color: #dedede;
}

@media screen and (max-width: 600px) {
    .brand-img {
        width: 50%;
    }
}
</style>
