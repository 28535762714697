<template>
    <div>
        <v-dialog v-model="isShowDialog">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="success" small v-bind="attrs" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> 新增人員至此管理類別-{{ adminGroup.name }} </v-card-title>
                <v-container>
                    <v-form ref="form" v-model="isFormValid" @submit.prevent="isFormValid && getUserByAccount()">
                        <v-row class="container-header each-item-row">
                            <v-col cols="3" class="same-category-each-cell">搜尋帳號</v-col>
                            <v-col cols="3" class="same-category-each-cell">帳號</v-col>
                            <v-col cols="3" class="same-category-each-cell">姓名</v-col>
                            <v-col cols="3" class="same-category-each-cell">操作</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <v-text-field v-model="searchAccount" :rules="accountRules" outlined hide-details="auto" dense required autofocus maxlength="10"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <span>{{ user.account }}</span>
                            </v-col>
                            <v-col cols="3">
                                <span>{{ user.name }}</span>
                            </v-col>
                            <v-col cols="3">
                                <v-btn :loading="searchButtonLoading" @click="getUserByAccount">搜尋</v-btn>
                                <v-btn color="success" :loading="submitButtonLoading" :disabled="!isGetUser" @click="createUserAdminGroup">新增</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "../api/api.js";

export default {
    name: "CreateUserAdminGroupDialog",

    props: {
        adminGroup: Object,
    },

    data() {
        return {
            isShowDialog: false,
            isFormValid: false,
            searchAccount: "",
            user: {
                account: "",
                name: "",
            },
            accountRules: [v => !!v || "必填", v => v.length <= 10 || "超出長度限制"],
            isGetUser: false,
            searchButtonLoading: false,
            submitButtonLoading: false,
        };
    },

    watch: {
        isShowDialog(val) {
            if (val === false) {
                this.resetForm();
            }
        },
    },

    methods: {
        getUserByAccount() {
            this.searchButtonLoading = true;
            this.isGetUser = false;

            api.getUserByAccount({
                params: {
                    account: this.searchAccount,
                },
            })
                .then(response => {
                    if (response.status === 200) {
                        this.user = response.data;
                        this.isGetUser = true;

                        this.$toasted.show("搜尋成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.resetResult();

                        this.$toasted.show("此帳號不存在。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.resetResult();

                    this.$toasted.show("獲取資料失敗。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.searchButtonLoading = false;
                });
        },

        createUserAdminGroup() {
            this.submitButtonLoading = true;

            api.createUserAdminGroup({
                account: this.user.account,
                admin_group_id: this.adminGroup.id,
            })
                .then(response => {
                    if (response.status === 201) {
                        this.isShowDialog = false;
                        this.resetForm();
                        this.$emit("getAdminGroups");

                        this.$toasted.show("新增成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("新增失敗，該使用者已在此管理類別。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("新增失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },

        resetResult() {
            this.user.account = "";
            this.user.name = "";
        },

        resetForm() {
            this.$refs.form.resetValidation();
            this.searchAccount = "";
            this.isGetUser = false;
            this.resetResult();
        },
    },
};
</script>

<style scoped>
.container {
    border: 2px solid #000000;
}

.col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.v-btn {
    margin: 5px;
}

.same-category-each-cell {
    border-right: 1px solid #efefef;
}

.container-header {
    background-color: #dedede;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}
</style>
