<template>
    <div>
        <adminVisitorAppBar></adminVisitorAppBar>
        <v-overlay :value="isCheckingTokenValid">
            <v-progress-circular :indeterminate="isCheckingTokenValid" color="primary"></v-progress-circular>
        </v-overlay>
        <h1 class="mt-10">文件申請系統-後臺管理系統</h1>
        <h3>請輸入帳號(身分證字號)、密碼：</h3>
        <v-form v-model="infoValid">
            <v-container style="min-width: 300px">
                <v-row>
                    <v-col cols="3" class="text">帳號</v-col>
                    <v-col cols="9">
                        <v-text-field v-model="account" :rules="accountRules" outlined autofocus required hide-details="auto" maxlength="10" placeholder="校務系統帳號"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" class="text">密碼</v-col>
                    <v-col cols="9">
                        <v-text-field v-model="password" type="password" :rules="passwordRules" outlined required hide-details="auto" placeholder="同校務行政系統密碼"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn type="submit" @click.prevent="loginOfAdmin" block color="#00BFFF" :loading="submitButtonLoading">登入</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
import api from "../api/api.js";
import adminVisitorAppBar from "../components/AdminVisitorAppBar.vue";

export default {
    name: "LoginOfAdmin",

    components: {
        adminVisitorAppBar,
    },

    data() {
        return {
            infoValid: false,
            account: "",
            password: "",
            accountRules: [v => !!v || "帳號不得為空"],
            passwordRules: [v => !!v || "密碼不得為空"],
            submitButtonLoading: false,
            isCheckingTokenValid: true,
        };
    },

    mounted() {
        this.checkTokenIsValid();
    },

    methods: {
        loginOfAdmin() {
            if (this.infoValid) {
                this.submitButtonLoading = true;

                api.loginOfAdmin({
                    account: this.account,
                    password: this.password,
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.$cookies.set("Authorization", "Bearer " + response.data["token"], "1d");
                            this.$cookies.set("key", response.data["key"], "1d");
                            this.$router.replace("/admin-order");
                        } else if (response.status === 403) {
                            this.$toasted.show("您非管理員。", {
                                type: "error",
                                duration: 2000,
                            });
                        } else {
                            this.$toasted.show("帳號或密碼錯誤，請重新登入。", {
                                type: "error",
                                duration: 2000,
                            });
                        }
                    })
                    .catch(() => {
                        this.$toasted.show("伺服器錯誤，請稍後再試", {
                            type: "error",
                            duration: 2000,
                        });
                    })
                    .finally(() => {
                        this.submitButtonLoading = false;
                    });
            }
        },

        checkTokenIsValid() {
            let token = this.$cookies.get("Authorization");

            if (!token) {
                this.isCheckingTokenValid = false;
                return;
            }

            api.me()
                .then(response => {
                    if (response.status === 200) {
                        this.$router.replace("/admin-order");
                    } else {
                        this.$toasted.show("您非管理員。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("伺服器錯誤，請稍後再試", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isCheckingTokenValid = false;
                });
        },
    },
};
</script>

<style scoped>
.container {
    margin: 0 auto;
    width: 30%;
    min-width: 300px;
    border: 2px solid #00aa00;
}

.text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

@media screen and (max-width: 600px) {
    .container {
        width: 80%;
    }
}
</style>
