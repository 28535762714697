<template>
    <div>
        <v-dialog v-model="isShowDialog">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="success" v-bind="attrs" v-on="on"> 詳細 </v-btn>
            </template>
            <v-card class="py-2">
                <v-container class="my-2">
                    <v-row class="each-item-row">
                        <v-col cols="2" class="same-category-each-cell">訂單編號</v-col>
                        <v-col cols="2" class="same-category-each-cell">{{ order.uuid }}</v-col>
                        <v-col cols="2" class="same-category-each-cell">學號</v-col>
                        <v-col cols="2" class="same-category-each-cell">{{ order.user.account }}</v-col>
                        <v-col cols="2" class="same-category-each-cell">姓名</v-col>
                        <v-col cols="2">{{ order.user.name }}</v-col>
                    </v-row>
                    <v-row class="each-item-row">
                        <v-col cols="2" class="same-category-each-cell">校區</v-col>
                        <v-col cols="2" class="same-category-each-cell">{{ order.user.area }}</v-col>
                        <v-col cols="2" class="same-category-each-cell">申請日期</v-col>
                        <v-col cols="2" class="same-category-each-cell">{{ order.create_date | dateFormat }}</v-col>
                        <v-col cols="2" class="same-category-each-cell">付款方式</v-col>
                        <v-col cols="2">{{ order.payment }}</v-col>
                    </v-row>
                    <v-row class="each-item-row">
                        <v-col cols="2" class="same-category-each-cell">付款狀態</v-col>
                        <v-col cols="2" class="same-category-each-cell">{{ order.payment_status === true ? "已付款" : "未付款" }}</v-col>
                        <v-col cols="2" class="same-category-each-cell">訂單狀態</v-col>
                        <v-col cols="2" class="same-category-each-cell">{{ order.progress }}</v-col>
                    </v-row>
                    <v-row class="each-item-row" style="background-color: #ffe66f">
                        <v-col>申請內容</v-col>
                    </v-row>
                    <v-row class="each-item-row">
                        <v-col cols="5" class="same-category-each-cell">申請項目</v-col>
                        <v-col cols="1" class="same-category-each-cell">單價</v-col>
                        <v-col cols="1" class="same-category-each-cell">份數</v-col>
                        <v-col cols="1" class="same-category-each-cell">合計</v-col>
                        <v-col cols="4">備註</v-col>
                    </v-row>
                    <v-row v-for="(orderDetail, index) in order.order_detail" :key="index" class="each-item-row">
                        <v-col cols="5" class="same-category-each-cell">{{ orderDetail.document }}</v-col>
                        <v-col cols="1" class="same-category-each-cell">{{ orderDetail.unit_price }}</v-col>
                        <v-col cols="1" class="same-category-each-cell">{{ orderDetail.amount }}</v-col>
                        <v-col cols="1" class="same-category-each-cell">{{ orderDetail.sub_total }}</v-col>
                        <v-col cols="4">{{ orderDetail.remark }}</v-col>
                    </v-row>
                    <v-row class="each-item-row">
                        <v-col cols="7" class="same-category-each-cell">總計</v-col>
                        <v-col cols="1">{{ order.price }}</v-col>
                    </v-row>
                    <v-row class="each-item-row" style="background-color: #ffe66f">
                        <v-col>領取方式</v-col>
                    </v-row>
                    <v-row class="each-item-row">
                        <v-col cols="3" class="same-category-each-cell">{{ order.delivery }}</v-col>
                        <v-col cols="9" v-if="order.order_delivery !== null">{{ order.order_delivery.address }}</v-col>
                        <v-col cols="9" v-if="order.order_delivery == null">{{ order.area }}校區</v-col>
                    </v-row>
                    <v-row v-if="order.order_delivery !== null" class="each-item-row">
                        <v-col cols="3" class="same-category-each-cell">取件人姓名</v-col>
                        <v-col cols="3" class="same-category-each-cell">{{ order.order_delivery.name }}</v-col>
                        <v-col cols="3" class="same-category-each-cell">取件人電話</v-col>
                        <v-col cols="3">{{ order.order_delivery.phone }}</v-col>
                    </v-row>
                    <v-row class="each-item-row" style="background-color: #ffe66f">
                        <v-col>訂單進度</v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <progressBar :progressTimeline="order.orderProgressTimeline"></progressBar>
                            <pickoutqrcodedialog v-if="orderStatusNow" :order="order"></pickoutqrcodedialog>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from "moment";
import progressBar from "../components/ProgressBar.vue";
import pickoutqrcodedialog from "../components/PickOutQrcodeDialog.vue";

export default {
    name: "ApplicationRecordDetail",

    components: {
        progressBar,
        pickoutqrcodedialog,
    },

    props: {
        order: Object,
    },

    filters: {
        dateFormat: function (value) {
            if (value) {
                return moment(String(value)).format("YYYY-MM-DD HH:mm");
            }
        },
    },

    data() {
        return {
            isShowDialog: false,
            orderStatusNow: false,
        };
    },

    mounted() {
        this.orderstatus();
    },

    methods: {
        orderstatus() {
            console.log(this.order.next_progress.id);
            if (this.order.next_progress.id == 7) {
                this.orderStatusNow = true;
            }
        },
    },
};
</script>

<style scoped>
.container {
    border: 2px solid #000000;
}

.col {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 4px;
}

.same-category-each-cell {
    border-right: 1px solid #000000;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}
</style>
