<template>
    <div>
        <v-app-bar dense>
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
            <v-toolbar-title>
                <img src="https://www.nkust.edu.tw/var/file/0/1000/img/513/182513897.png" alt="國立高雄科技大學文件申請系統後臺" style="height: 40px" />
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div v-if="!isMobile">
                <router-link to="/admin" @click.native="logout" style="margin-right: 10px">登入</router-link>
            </div>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" absolute temporary v-if="isMobile">
            <v-list nav dense>
                <v-list-item-group>
                    <v-list-item to="/admin" style="margin-right: 10px">
                        <v-list-item-icon>
                            <v-icon>mdi-login</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>登入</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: "AdminVisitorAppBar",

    data() {
        return {
            drawer: false,
            isMobile: [],
        };
    },

    methods: {
        checkIsMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            return flag;
        },
    },

    mounted() {
        this.isMobile = this.checkIsMobile();
    },
};
</script>

<style scoped></style>
