<template>
    <div>
        <v-overlay :value="!isGetOrders">
            <v-progress-circular :indeterminate="!isGetOrders" color="primary"></v-progress-circular>
        </v-overlay>
        <ordererAppBar></ordererAppBar>
        <h1 class="mt-10">文件申請系統</h1>
        <div v-if="!orders.length">目前無申請紀錄</div>
        <div v-if="!isMobile">
            <v-container class="orders-box" v-for="order in orders" :key="order.id">
                <v-row class="mb-0 each-item-row container-header">
                    <v-col cols="2" class="same-category-each-cell">申請編號</v-col>
                    <v-col cols="2" class="same-category-each-cell">付款狀態</v-col>
                    <v-col cols="1" class="same-category-each-cell">總金額</v-col>
                    <v-col cols="1" class="same-category-each-cell">訂單狀態</v-col>
                    <v-col cols="2" class="same-category-each-cell">申請日期</v-col>
                    <v-col cols="2" class="same-category-each-cell">操作</v-col>
                    <v-col cols="2" class="same-category-each-cell">操作</v-col>
                </v-row>
                <v-row class="my-0">
                    <v-col cols="2" style="word-wrap: break-word">{{ order.uuid }}</v-col>
                    <v-col cols="2">{{ order.paid === true ? "已付款" : "未付款" }}</v-col>
                    <v-col cols="1">$ {{ order.total_price }}</v-col>
                    <v-col cols="1">{{ order.progress }}</v-col>
                    <v-col cols="2" style="word-wrap: break-word">{{ order.create_date | dateFormat }}</v-col>
                    <v-col cols="2">
                        <applicationRecordDetail :order="order"></applicationRecordDetail>
                    </v-col>
                    <v-col cols="2">
                        <!-- <v-btn v-if="order.paid === true" href="https://pay.nkust.edu.tw:9999/pdf.php" color="success">列印繳款證明</v-btn> -->
                        <!-- <pdf v-if="order.paid === true" :order="order"></pdf> -->
                        <!-- <proofofpayment v-if="order.paid === true" :order="order"></proofofpayment> -->
                        <v-btn color="success" v-if="order.paid === true" @click="getPdfProofPayments(order)">列印繳費證明</v-btn>
                        <v-btn v-if="order.paid === false" color="success" @click="linePayPaymentsRequest(order)" :loading="rePayButtonLoading">重新付款</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div v-if="isMobile">
            <div class="orders-box text-left" v-for="order in orders" :key="order.id">
                <div>
                    <p class="mb-0" style="background-color: #555555; color: #ffffff">申請編號：{{ order.uuid }}</p>
                    <p class="mb-0">取貨方式：{{ order.delivery.delivery }}</p>
                    <p class="mb-0">付款方式：{{ order.payment.payment }}</p>
                    <p class="mb-0">付款狀態：{{ order.paid === true ? "已付款" : "未付款" }}</p>
                    <p class="mb-0">金額：${{ order.price }}</p>
                    <p class="mb-0">運費：${{ order.delivery_fee }}</p>
                    <p class="mb-0">總金額：${{ order.total_price }}</p>
                    <p class="mb-0" v-if="order.order_delivery">收貨人電話：{{ order.order_delivery.phone }}</p>
                    <p class="mb-0" v-if="order.order_delivery">收貨人地址：{{ order.order_delivery.address }}</p>
                    <p class="mb-0" v-if="order.order_delivery">收貨人地址：{{ order.order_delivery.address }}</p>
                    <p class="mb-0">申請時間：{{ order.create_date | dateFormat }}</p>
                    <p class="mb-0">備註：{{ order.remark }}</p>
                    <ordererModifyOrder v-if="!order.paid" :order="order" :payments="payments" :width="'50%'"></ordererModifyOrder>
                </div>
            </div>
        </div>
        <v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom right color="primary" @click="toTop">
            <v-icon>mdi-apple-keyboard-control</v-icon>
        </v-btn>
    </div>
</template>

<script>
import api from "../api/api.js";
import applicationRecordDetail from "../components/ApplicationRecordDetail.vue";
import ordererAppBar from "../components/OrdererAppBar.vue";
import moment from "moment";

export default {
    name: "ApplicationRecord",

    components: {
        applicationRecordDetail,
        ordererAppBar,
    },

    filters: {
        dateFormat: function (value) {
            if (value) {
                return moment(String(value)).format("YYYY-MM-DD");
            }
        },
    },

    data() {
        return {
            isMobile: [],
            fab: false,
            orders: [],
            isGetOrders: false,
            payments: [],
            isGetPayments: false,
            progresses: [],
            isGetprogresses: false,
            rePayButtonLoading: false,
        };
    },

    mounted() {
        if (this.checkTokenIsValid()) {
            this.isMobile = this.checkIsMobile();
            this.getOrders();
            this.getPayments();
        }
    },

    methods: {
        getOrders() {
            api.getOrders({
                params: {
                    type: "user",
                },
            })
                .then(response => {
                    if (response.status === 200) {
                        this.orders = response.data;
                        console.log(this.orders);
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetOrders = true;
                });
        },

        getPayments() {
            api.getPayments()
                .then(response => {
                    if (response.status === 200) {
                        this.payments = response.data;
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetPayments = true;
                });
        },
        linePayPaymentsRequest(order) {
            this.rePayButtonLoading = true;

            api.linePayPaymentsRequest({
                order_id: order.id,
                price: order.price,
            })
                .then(response => {
                    let linePayRequestURL = response.data["info"]["paymentUrl"]["web"];
                    window.location.href = linePayRequestURL;
                })
                .catch(() => {
                    this.$toasted.show("付款失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.rePayButtonLoading = false;
                });
        },
        getPdfProofPayments(order) {
            api.getPdfProofPayments({
                order_id: order.id,
            })

                .then(response => {
                    let ProofPaymentsURL = response.data;
                    window.open(ProofPaymentsURL);
                    // const link = document.createElement("a");
                    // link.href = url;
                    // link.download = "前端工程师必备技能.pdf";
                    // link.click();
                })
                .catch(() => {
                    this.$toasted.show("產生繳費證明失敗。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        goToOrderDetail(orderId) {
            this.$router.replace("/application-record/" + orderId);
        },

        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 20;
        },

        toTop() {
            this.$vuetify.goTo(0);
        },

        checkIsMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            return flag;
        },

        checkTokenIsValid() {
            let token = this.$cookies.get("Authorization");

            if (!token) {
                this.isCheckingTokenValid = false;
                this.$router.replace("/login");
                return false;
            }

            return true;
        },
    },
};
</script>

<style scoped>
.col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.orders-box {
    width: 70%;
    margin: 0 auto;
    min-width: 300px;
    border: 2px solid #000000;
    margin-bottom: 20px;
}

.same-category-each-cell {
    border-right: 1px solid #efefef;
}

.container-header {
    background-color: #dedede;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}

.different-category-row {
    border-bottom: 2px solid #000000;
}

.v-expansion-panel-content__wrap {
    padding: 0px;
}

@media screen and (max-width: 600px) {
    .orders-box {
        width: 100%;
    }
}
</style>
