<template>
    <div>
        <div v-if="progressTimeline.length > 0" class="d-flex">
            <div v-for="(progress, index) in progressTimeline" :key="index" class="mx-1 justify-center each-progress">
                <div class="bar" :class="{ 'solid-bar': progressTimeline[index].orderProgressTimelineInfo }"></div>
                <p v-if="index != 2" class="mb-0 text-center">{{ progress.name }}</p>
                <p v-if="index == 2" class="mb-0 text-center">待領取</p>
                <p v-if="progress.orderProgressTimelineInfo" class="mb-0 text-center">{{ progress.orderProgressTimelineInfo.create_date | dateFormat }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "ProgressBar",

    props: {
        progressTimeline: Array,
    },

    filters: {
        dateFormat: function (value) {
            if (value) {
                return moment(String(value)).format("MM/DD HH:mm");
            }
        },
    },
};
</script>

<style scoped>
.each-progress {
    width: 20%;
    min-width: 75px;
}

.bar {
    width: 100%;
    height: 6px;
    border: 2px #3e70ff solid;
}

.solid-bar {
    background-color: #3e70ff;
}

@media screen and (max-width: 600px) {
    .each-bar {
        width: 18%;
    }
}
</style>
