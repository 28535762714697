<template>
    <v-app>
        <router-view />
        <copyRight class="mt-16"></copyRight>
    </v-app>
</template>
<script>
import copyRight from "./components/CopyRight.vue";
export default {
    components: {
        copyRight,
    },
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
