<template>
    <div>
        <v-overlay :value="isLoading">
            <v-progress-circular :indeterminate="isLoading" color="primary"></v-progress-circular>
        </v-overlay>
        <ordererAppBar></ordererAppBar>
        <h1 class="mt-10">文件申請系統</h1>
        <v-card class="document-card" v-if="!documentClasses.length">
            <v-card-title class="cyan">目前無資料</v-card-title>
        </v-card>
        <!-- <v-card class="document-card" v-if="documentClasses.length">
            <v-tabs v-model="documentClassesTabs" align-with-title background-color="cyan" show-arrows>
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab v-for="(documentClass, index) in documentClasses" :key="index">
                    {{ documentClass.name }}
                </v-tab>
            </v-tabs> -->
        <v-tabs-items v-model="documentClassesTabs">
            <v-tab-item v-for="(documentClass, index) in documentClasses" :key="index">
                <v-container class="pb-0">
                    <div v-if="!documentClass.document.length">
                        <v-row>
                            <v-col>目前尚無文件</v-col>
                        </v-row>
                    </div>
                    <div v-if="documentClass.document.length">
                        <v-row>
                            <v-col cols="4" lg="2" class="same-category-each-cell" align-self="center">姓名：{{ users[0].name }}</v-col>
                            <v-col cols="4" lg="2" class="same-category-each-cell" align-self="center">生日：{{ users[0].birthday }} </v-col>
                            <v-col cols="4" lg="2" class="same-category-each-cell" align-self="center">身分證字號：{{ users[0].personal_id }} </v-col>
                            <v-col cols="4" lg="2" class="same-category-each-cell" align-self="center">連絡電話：{{ users[0].phone }} </v-col>
                            <v-col cols="4" lg="2" class="same-category-each-cell" align-self="center">Email：{{ users[0].email }}Email </v-col>
                        </v-row>
                        <v-row class="mb-0 each-item-row container-header">
                            <v-col cols="4" lg="5" class="same-category-each-cell" align-self="center">學制</v-col>
                            <v-col cols="4" lg="5" class="same-category-each-cell" align-self="center">學號</v-col>
                            <!-- <v-col cols="2" lg="1" class="same-category-each-cell" align-self="center"></v-col> -->
                            <v-col cols="2" lg="2" class="same-category-each-cell" align-self="center">選項</v-col>
                        </v-row>
                        <v-row v-for="(document, index) in documentClass.document" :key="index" class="my-0 each-item-row">
                            <v-col cols="4" lg="5" align-self="center" class="text-left">{{ document.name }}</v-col>
                            <v-col cols="4" lg="5" align-self="center" class="text-left">{{ document.ecnt }}</v-col>
                            <v-col cols="2" lg="1" align-self="center"> </v-col>
                            <v-col cols="2" lg="1" align-self="center" class="pa-0">
                                <createShoppingCartDialog :document="document" :academicYears="academicYears"></createShoppingCartDialog>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </v-tab-item>
        </v-tabs-items>
        <!-- </v-card> -->
    </div>
</template>

<script>
import api from "../api/api.js";
import ordererAppBar from "../components/OrdererAppBar.vue";
import createShoppingCartDialog from "../components/CreateShoppingCartDialog.vue";

export default {
    name: "Identify",

    components: {
        ordererAppBar,
        createShoppingCartDialog,
    },

    data() {
        return {
            isGetDocuments: false,
            documentClasses: [],
            documentClassesTabs: 0,
            academicYears: [],
            isGetAcademicYears: false,
            users: [],
            isGetUsers: false,
        };
    },

    computed: {
        isLoading() {
            return !(this.isGetDocuments && this.isGetAcademicYears);
        },
    },

    mounted() {
        if (this.checkTokenIsValid() === true) {
            this.getDocumentClasses();
            this.getAcademicYears();
            this.getUserSamePersonalId();
        }
    },

    methods: {
        getDocumentClasses() {
            api.getDocumentClasses()
                .then(response => {
                    this.documentClasses = response.data;
                })
                .catch(() => {
                    this.$toasted.show("加入失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetDocuments = true;
                });
        },

        getUserSamePersonalId() {
            this.isGetUsers = false;

            api.getUserSamePersonalId()
                .then(response => {
                    if (response.status === 200) {
                        this.users = response.data;
                        if (this.users.length < 2) {
                            this.$cookies.set("Authorization", "Bearer " + this.users[0]["token"], "1d");
                            this.$router.replace("/");
                        }
                    } else {
                        this.$toasted.show("伺服器錯誤", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetUsers = true;
                });
        },

        getAcademicYears() {
            api.getAcademicYears()
                .then(response => {
                    this.academicYears = response.data;
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetAcademicYears = true;
                });
        },

        createShoppingCart(document) {
            api.createShoppingCart({ document_id: document.id, amount: 1 })
                .then(() => {
                    this.$toasted.show("成功加入購物車", {
                        type: "success",
                        duration: 2000,
                    });
                })
                .catch(() => {
                    this.$toasted.show("加入失敗", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        checkTokenIsValid() {
            let token = this.$cookies.get("Authorization");

            if (!token) {
                this.isCheckingTokenValid = false;
                this.$router.replace("/login");
                return false;
            }

            return true;
        },
        chooseAcademicSystem(user) {
            this.$cookies.set("Authorization", "Bearer " + user.token, "1d");
            this.$router.replace("/");
        },
    },
};
</script>

<style scoped>
body {
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.brand-img {
    width: 300px;
}

.col {
    display: flex;
    align-items: center;
    justify-content: center;
}

.document-card {
    width: 70%;
    min-width: 300px;
    margin: 0 auto;
}

.same-category-each-cell {
    border-right: 1px solid #efefef;
}

.container-header {
    background-color: #dedede;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}

.different-category-row {
    border-bottom: 2px solid #000000;
}

@media screen and (max-width: 600px) {
    .brand-img {
        width: 50%;
    }
    .document-card {
        width: 100%;
    }
}
</style>
