<template>
    <div>
        <img src="https://www.nkust.edu.tw/var/file/0/1000/img/513/539900619.png" width="300" />
        <h1>資料申請系統</h1>
        <div>
            <span v-if="!isPaidSuccessful && !isPaidFailed">付款中...請勿關閉此頁面</span>
            <span v-if="isPaidSuccessful">您已成功使用Linepay付款，5秒後自動導回首頁。</span>
            <span v-if="isPaidFailed">付款失敗，請重新申請!</span>
        </div>
    </div>
</template>

<script>
import api from "../api/api.js";

export default {
    name: "LinePayPaymentsConfirm",

    data() {
        return {
            isPaidSuccessful: false,
            isPaidFailed: false,
        };
    },

    methods: {
        linePayPaymentsConfirm() {
            api.linePayPaymentsConfirm({
                transactionId: this.$route.query.transactionId,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.isPaidSuccessful = true;
                    } else {
                        this.isPaidFailed = true;
                    }
                })
                .catch(() => {
                    this.isPaidFailed = true;
                })
                .finally(() => {
                    setTimeout(() => {
                        this.$router.replace("/application-record");
                    }, 1000 * 5);
                });
        },
    },

    mounted() {
        this.linePayPaymentsConfirm();
    },
};
</script>

<style scoped>
body {
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
}
</style>
