<template>
    <div>
        <v-dialog v-model="isShowDialog">
            <template v-slot:activator="{ on, attrs }">
                <v-chip :color="delivery.valid === true ? 'green' : 'red'" outlined v-bind="attrs" v-on="on"> {{ delivery.name }} </v-chip>
            </template>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> 編輯取貨方式 </v-card-title>
                <v-container>
                    <v-row class="container-header each-item-row">
                        <v-col cols="2" class="same-category-each-cell">取貨方式</v-col>
                        <v-col cols="2" class="same-category-each-cell">取貨費用</v-col>
                        <v-col cols="2" class="same-category-each-cell">取貨資訊</v-col>
                        <v-col cols="2" class="same-category-each-cell">顯示</v-col>
                        <v-col cols="4">操作</v-col>
                    </v-row>
                    <v-row class="each-item-row">
                        <v-col cols="2" class="same-category-each-cell">
                            <p v-if="!isEdit" class="ma-0">{{ delivery.name }}</p>
                            <v-text-field v-if="isEdit" v-model="nameTemp" outlined hide-details="auto" dense maxlength="20"></v-text-field>
                        </v-col>
                        <v-col cols="2" class="same-category-each-cell">
                            <p v-if="!isEdit" class="ma-0">$ {{ delivery.fee }}</p>
                            <v-text-field v-if="isEdit" v-model.number="feeTemp" outlined hide-details="auto" dense min="0" max="1000"></v-text-field>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-center same-category-each-cell">
                            <p v-if="!isEdit" class="ma-0">{{ delivery.require_info === true ? "是" : "否" }}</p>
                            <v-checkbox v-if="isEdit" v-model="requireInfoTemp" hide-details="auto" dense></v-checkbox>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-center same-category-each-cell">
                            <p v-if="!isEdit" class="ma-0">{{ delivery.valid === true ? "是" : "否" }}</p>
                            <v-checkbox v-if="isEdit" v-model="validTemp" hide-details="auto" dense></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-btn v-if="pageActions.data_setting_delivery_edit" @click="isEdit = true" color="primary" :disabled="isEdit">修改</v-btn>
                            <v-dialog v-model="isShowDeleteConfirmDialog" width="70%">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-if="pageActions.data_setting_delivery_delete" color="error" v-bind="attrs" v-on="on"> 刪除 </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="text-h5 grey lighten-2"> 確定要刪除此取貨方式嗎? </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="deleteDelivery" :loading="submitButtonLoading"> 刪除 </v-btn>
                                        <v-btn @click="isShowDeleteConfirmDialog = false" :disabled="submitButtonLoading"> 取消 </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-btn v-if="pageActions.data_setting_delivery_edit" @click="resetValue" :disabled="!isEdit || submitButtonLoading">取消</v-btn>
                            <v-btn v-if="pageActions.data_setting_delivery_edit" @click="updateDelivery" color="success" :disabled="!isEdit" :loading="submitButtonLoading">儲存</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2" class="same-category-each-cell container-header">訂單進度顯示</v-col>
                        <v-col class="same-category-each-cell justify-start">
                            <modifyProgressDialog v-for="(progress, index) in delivery.progress" :key="index" :progress="progress" :pageActions="pageActions" @deleteProgress="deleteProgress"></modifyProgressDialog>
                        </v-col>
                        <v-col cols="2">
                            <createProgressmentDialog v-if="pageActions.data_setting_progress_add" @deleteProgress="deleteProgress" @getDeliveries="getDeliveries" :deliveryId="delivery.id"></createProgressmentDialog>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "../api/api.js";
import modifyProgressDialog from "../components/ModifyProgressDialog.vue";
import createProgressmentDialog from "../components/CreateProgressDialog.vue";

export default {
    name: "ModifyDeliveryDialog",

    components: {
        modifyProgressDialog,
        createProgressmentDialog,
    },

    props: {
        pageActions: Object,
        delivery: Object,
    },

    data() {
        return {
            isShowDialog: false,
            isEdit: false,
            isShowDeleteConfirmDialog: false,
            nameTemp: "",
            feeTemp: 0,
            validTemp: false,
            requireInfoTemp: false,
            submitButtonLoading: false,
        };
    },

    watch: {
        isShowDialog(val) {
            if (val === false) {
                this.resetValue();
            }
        },
    },

    methods: {
        getDeliveries() {
            this.$emit("getDeliveries");
        },

        updateDelivery() {
            this.isEdit = false;
            this.submitButtonLoading = true;

            api.updateDelivery({
                id: this.delivery.id,
                name: this.nameTemp,
                fee: this.feeTemp,
                valid: this.validTemp,
                require_info: this.requireInfoTemp,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.delivery.name = this.nameTemp;
                        this.delivery.fee = this.feeTemp;
                        this.delivery.valid = this.validTemp;
                        this.delivery.require_info = this.requireInfoTemp;

                        this.$toasted.show("修改成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("修改失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },

        deleteDelivery() {
            this.submitButtonLoading = true;

            api.deleteDelivery({
                id: this.delivery.id,
            })
                .then(response => {
                    if (response.status === 202) {
                        this.isShowDeleteConfirmDialog = false;
                        this.isShowDialog = false;
                        this.$emit("deleteDelivery", this.delivery);

                        this.$toasted.show("刪除成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("修改失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("修改失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },

        getProgresses() {
            api.getProgresses()
                .then(response => {
                    this.delivery.progress = response.data;
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        deleteProgress(progress) {
            this.delivery.progress.splice(this.delivery.progress.indexOf(progress), 1);
        },

        resetValue() {
            this.isEdit = false;
            this.nameTemp = this.delivery.name;
            this.feeTemp = this.delivery.fee;
            this.validTemp = this.delivery.valid;
            this.requireInfoTemp = this.delivery.require_info;
        },
    },

    mounted() {
        this.resetValue();
    },
};
</script>

<style scoped>
.v-chip {
    margin-left: 5px;
    margin-right: 5px;
}

.container {
    border: 2px solid #000000;
}

.col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 4px;
}

.v-btn {
    margin: 5px;
}

.same-category-each-cell {
    border-right: 1px solid #000000;
}

.container-header {
    background-color: #dedede;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}
</style>
