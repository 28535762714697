<template>
    <div>
        <v-app-bar dense>
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
            <v-toolbar-title>
                <img src="https://www.nkust.edu.tw/var/file/0/1000/img/513/182513897.png" alt="國立高雄科技大學文件申請系統後臺" style="height: 40px" />
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div v-if="!isMobile">
                <router-link to="#" @click.native="logout" style="margin-right: 10px">登出</router-link>
                <router-link v-if="pages.order_management" to="/admin-order" style="margin-right: 10px">訂單管理</router-link>
                <router-link v-if="pages.data_setting" to="/admin-setting" style="margin-right: 10px">設定</router-link>
                <router-link v-if="pages.financial_statement" to="/admin-financial-statement" style="margin-right: 10px">銷帳報表</router-link>
                <router-link v-if="pages.admin_setting" to="/admin-admin" style="margin-right: 10px">管理員設定</router-link>
            </div>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" absolute temporary v-if="isMobile">
            <v-list nav dense>
                <v-list-item-group>
                    <v-list-item v-if="pages.order_management" to="/admin-order" style="margin-right: 10px">
                        <v-list-item-icon>
                            <v-icon>mdi-cart</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>訂單管理</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="pages.data_setting" to="/admin-setting" style="margin-right: 10px">
                        <v-list-item-icon>
                            <v-icon>mdi-file-document-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>設定</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="pages.financial_statement" to="/admin-financial-statement" style="margin-right: 10px">
                        <v-list-item-icon>
                            <v-icon>mdi-currency-usd</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>銷帳報表</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="pages.admin_setting" to="/admin-admin" style="margin-right: 10px">
                        <v-list-item-icon>
                            <v-icon>mdi-account-details</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>管理員設定</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="#" @click.native="logout" style="margin-right: 10px">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>登出</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import api from "../api/api.js";

export default {
    name: "AdminAppBar",

    data() {
        return {
            drawer: false,
            isMobile: [],
            pages: {},
            pageList: ["order_management", "financial_statement", "data_setting", "admin_setting"],
        };
    },

    methods: {
        checkKeyIsValidForComponent() {
            let key = this.$cookies.get("key");

            this.pageList.forEach(element => {
                if (key.indexOf(element) == -1) {
                    this.$set(this.pages, element, false);
                } else {
                    this.$set(this.pages, element, true);
                }
            });
        },

        checkIsMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            return flag;
        },

        logout() {
            api.logout().finally(() => {
                this.$cookies.remove("Authorization");
                this.$cookies.remove("key");
                this.$cookies.remove("verified");
                this.$router.push("/admin");
            });
        },
    },

    mounted() {
        this.isMobile = this.checkIsMobile();
        this.checkKeyIsValidForComponent();
    },
};
</script>

<style scoped></style>
