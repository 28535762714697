import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
import Toasted from "vue-toasted";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(Toasted);
Vue.use(VueGtag, {
    config: { id: "G-PD1ZV7KM40" },
    params: {
        send_page_view: false,
    },
});

let recaptchaScript = document.createElement("script");
recaptchaScript.setAttribute("type", "text/javascript");
recaptchaScript.textContent = "(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src='https://www.clarity.ms/tag/'+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, 'clarity', 'script', '7mz5ts7l0t');";
document.head.appendChild(recaptchaScript);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount("#app");
