<template>
    <div>
        <v-dialog v-model="isShowDeleteConfirmDialog" width="70%">
            <template v-slot:activator="{ on, attrs }">
                <v-btn small color="error" v-bind="attrs" v-on="on">
                    <v-icon>mdi-trash-can</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> 確定要刪除此管理員嗎? </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="deleteUserAdminGroup" :loading="submitButtonLoading"> 刪除 </v-btn>
                    <v-btn @click="isShowDeleteConfirmDialog = false" :disabled="submitButtonLoading"> 取消 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "../api/api.js";

export default {
    name: "DeleteUserAdminGroupDialog",

    props: {
        userAdminGroup: Object,
    },

    data() {
        return {
            isShowDeleteConfirmDialog: false,
            submitButtonLoading: false,
        };
    },

    methods: {
        deleteUserAdminGroup() {
            this.submitButtonLoading = true;

            api.deleteUserAdminGroup({
                id: this.userAdminGroup.id,
            })
                .then(response => {
                    if (response.status === 202) {
                        this.isShowDeleteConfirmDialog = false;
                        this.$emit("getAdminGroups");

                        this.$toasted.show("刪除成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("修改失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("修改失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },
    },
};
</script>

<style scoped>
.v-btn {
    margin: 5px;
}
</style>
