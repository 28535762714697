<template>
    <div>
        <v-overlay :value="!isGetAllInfo">
            <v-progress-circular :indeterminate="!isGetAllInfo" color="primary"></v-progress-circular>
        </v-overlay>
        <ordererAppBar ref="orderAppBar"></ordererAppBar>
        <h1 class="mt-10">文件申請系統</h1>
        <div v-if="shoppingCarts.length === 0">目前購物車是空的</div>
        <v-container class="shopping-cart-container pb-0" v-if="!isMobile && shoppingCarts.length !== 0 && isGetAllInfo">
            <div v-if="shoppingCarts.length !== 0">
                <v-row class="mb-0 each-item-row container-header">
                    <v-col cols="4" class="same-category-each-cell">申請項目</v-col>
                    <v-col cols="2" class="same-category-each-cell">數量</v-col>
                    <v-col cols="1" class="same-category-each-cell">單價</v-col>
                    <v-col cols="1" class="same-category-each-cell">小計</v-col>
                    <v-col cols="2" class="same-category-each-cell">備註</v-col>
                    <v-col cols="2" class="same-category-each-cell">操作</v-col>
                </v-row>
                <v-row class="my-0 each-item-row" v-for="(item, index) in shoppingCarts" :key="index" :class="{ 'different-category-row': shoppingCarts.length - 1 === index }">
                    <v-col cols="4">{{ item.document.name }}</v-col>
                    <v-col cols="2">
                        <!-- <v-btn @click="decrementItemCount(item)" fab :disabled="item.amount <= 1" x-small><v-icon dark> mdi-minus </v-icon></v-btn> -->
                        {{ item.amount }}
                        <!-- <v-btn @click="incrementItemCount(item)" fab :disabled="item.amount >= 10" x-small><v-icon dark> mdi-plus </v-icon></v-btn> -->
                    </v-col>
                    <v-col cols="1">$ {{ item.document.price }} </v-col>
                    <v-col cols="1">$ {{ item.subTotal }}</v-col>
                    <v-col cols="2">{{ item.remark }}</v-col>
                    <v-col cols="2">
                        <v-btn color="error" @click="deleteShoppingCart(item, index)">刪除</v-btn>
                    </v-col>
                </v-row>
                <v-row class="my-0 different-category-row">
                    <v-col cols="2" class="container-header" style="border-right: 1px solid #000000">運費</v-col>
                    <v-col cols="5"></v-col>
                    <v-col cols="1">$ {{ this.deliveryFee }}</v-col>
                    <v-col cols="3"></v-col>
                </v-row>
                <v-row class="my-0 different-category-row">
                    <v-col cols="2" class="container-header" style="border-right: 1px solid #000000">總價</v-col>
                    <v-col cols="5"></v-col>
                    <v-col cols="1">$ {{ claculateTotalPrice() }} </v-col>
                    <v-col cols="3"></v-col>
                </v-row>
                <v-form v-model="infoValid">
                    <v-row class="my-0 different-category-row">
                        <v-col cols="2" class="container-header" style="border-right: 1px solid #000000">備註</v-col>
                        <v-col cols="10">
                            <v-text-field v-model="remark" outlined dense hide-details="auto"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="isHaveEntity" class="my-0 different-category-row">
                        <v-col cols="2" class="container-header" style="border-right: 1px solid #000000">取貨方式</v-col>
                        <v-col cols="2" class="justify-start">
                            <v-radio-group class="mt-0" v-model="delivery" hide-details="auto">
                                <v-radio v-for="(delivery, index) in deliveries" :key="index" :label="delivery.name" :value="delivery"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col>
                            <div v-if="delivery.require_info === true">
                                <v-row class="my-0 mr-0">
                                    <v-col cols="6" class="d-flex pa-1">
                                        <span style="color: #ff0000">*</span>
                                        <span>收貨人姓名：</span>
                                        <v-text-field v-model="pickUpName" :rules="pickUpNameRules" outlined dense hide-details="auto" required maxlength="50"></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="d-flex pa-1">
                                        <span style="color: #ff0000">*</span>
                                        <span>收貨人手機：</span>
                                        <v-text-field type="tel" v-model="pickUpPhone" :rules="pickUpPhoneRules" outlined dense hide-details="auto" required maxlength="10"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="my-0 mr-0">
                                    <v-col class="d-flex pa-1">
                                        <span style="color: #ff0000">*</span>
                                        <span>收貨人地址：</span>
                                        <v-text-field v-model="pickUpAddress" :rules="pickUpAddressRules" outlined dense hide-details="auto" required maxlength="100"></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>
                            <div v-else>
                                <label v-if="shoppingCarts[0].change_area_id == 1">{{ users[0].area }}</label>

                                <v-radio-group v-if="shoppingCarts[0].change_area_id == 0" v-model="area" row>
                                    <v-radio v-for="(area, index) in areas" :key="index" :label="area.name" :value="area"></v-radio>
                                </v-radio-group>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="my-0 different-category-row">
                        <v-col cols="2" class="container-header" style="border-right: 1px solid #000000">付款方式</v-col>
                        <v-col cols="10" class="justify-start">
                            <v-radio-group v-model="payment" class="mt-0" hide-details="auto">
                                <v-radio v-for="(payment, index) in payments" :key="index" :label="payment.name" :value="payment"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row class="my-0">
                        <v-col class="text-right">
                            <v-btn type="submit" color="success" @click.prevent="createOrder" :disable="isGetAllInfo" :loading="submitButtonLoading">送出訂單</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </div>
        </v-container>
        <div v-if="isMobile && shoppingCarts.length !== 0">
            <v-card v-for="(item, index) in shoppingCarts" :key="index" outlined class="ma-2">
                <v-card-text class="text-left">
                    <p class="my-0">文件名稱：{{ item.document.name }}</p>
                    <p class="my-0">數量：{{ item.amount }}</p>
                    <p class="my-0">單價：$ {{ item.document.price }}</p>
                    <p class="my-0">小計：$ {{ item.document.price * item.amount }}</p>
                    <p class="my-0">備註：{{ item.remark }}</p>
                </v-card-text>
                <v-card-actions class="justify-right">
                    <v-btn color="error" @click="deleteShoppingCart(item, index)"> 刪除 </v-btn>
                </v-card-actions>
            </v-card>
            <v-form v-model="infoValid">
                <v-card v-if="isHaveEntity" class="ma-2" outlined>
                    <v-card-title class="text-left py-0" style="background-color: #dedede"> 取貨方式 </v-card-title>
                    <v-card-text class="text-left">
                        <v-radio-group class="mt-0" v-model="delivery" hide-details="auto">
                            <v-radio v-for="(delivery, index) in deliveries" :key="index" :label="delivery.name" :value="delivery"></v-radio>
                        </v-radio-group>
                        <div v-if="delivery.require_info === true">
                            <div class="d-flex my-1 align-center">
                                <span style="color: #ff0000">*</span>
                                <span class="mb-0">收貨人姓名：</span>
                                <v-text-field v-model="pickUpName" :rules="pickUpNameRules" outlined dense hide-details="auto" required maxlength="50"></v-text-field>
                            </div>
                            <div class="d-flex my-1 align-center">
                                <span style="color: #ff0000">*</span>
                                <span class="mb-0">收貨人手機：</span>
                                <v-text-field type="tel" v-model="pickUpPhone" :rules="pickUpPhoneRules" outlined dense hide-details="auto" required maxlength="10"></v-text-field>
                            </div>
                            <div class="d-flex my-1 align-center">
                                <span style="color: #ff0000">*</span>
                                <span class="mb-0">收貨人地址：</span>
                                <v-text-field v-model="pickUpAddress" :rules="pickUpAddressRules" outlined dense hide-details="auto" required maxlength="100"></v-text-field>
                            </div>
                        </div>
                        <div v-else>
                            <v-radio-group v-model="area" row>
                                <v-radio v-for="(area, index) in areas" :key="index" :label="area.name" :value="area"></v-radio>
                            </v-radio-group>
                        </div>
                    </v-card-text>
                </v-card>
                <v-card class="ma-2" outlined>
                    <v-card-title class="text-left py-0" style="background-color: #dedede"> 付款方式 </v-card-title>
                    <v-card-text class="text-left">
                        <v-radio-group v-model="payment" class="mt-0" hide-details="auto">
                            <v-radio v-for="(payment, index) in payments" :key="index" :label="payment.name" :value="payment"></v-radio>
                        </v-radio-group>
                    </v-card-text>
                </v-card>
                <v-card class="ma-2" outlined>
                    <v-card-title class="text-left py-0" style="background-color: #dedede"> 訂單資訊 </v-card-title>
                    <v-card-text class="text-left">
                        <p class="my-0">運費：$ {{ this.deliveryFee }}</p>
                        <p class="my-0">總金額：$ {{ claculateTotalPrice() }}</p>
                        <div class="d-flex my-0 align-center">
                            <p class="mb-0">備註：</p>
                            <v-text-field v-model="remark" outlined dense hide-details="auto"></v-text-field>
                        </div>
                    </v-card-text>
                </v-card>
                <v-btn type="submit" color="success" @click.prevent="createOrder" :disable="isGetAllInfo" :loading="submitButtonLoading">送出訂單</v-btn>
            </v-form>
        </div>
        <v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom right color="primary" @click="toTop">
            <v-icon>mdi-apple-keyboard-control</v-icon>
        </v-btn>
    </div>
</template>

<script>
import api from "../api/api.js";
import ordererAppBar from "../components/OrdererAppBar.vue";

export default {
    name: "ShoppingCart",

    components: {
        ordererAppBar,
    },

    data() {
        return {
            fab: false,
            shoppingCarts: [],
            isGetShoppingCarts: false,
            deliveries: [],
            isGetDeliveries: false,
            payments: [],
            isGetPayments: false,
            delivery: null,
            payment: null,
            remark: "",
            loading: false,
            pickUpName: "",
            pickUpPhone: "",
            pickUpAddress: "",
            pickUpNameRules: [v => !!v || "名字不得為空", v => v.length >= 2 || "請輸入正確名字"],
            pickUpPhoneRules: [
                v => !!v || "電話不得為空",
                v => v.length === 10 || "請輸入正確的電話號碼",
                v => {
                    const pattern = /^09\d{8}$/;
                    return pattern.test(v) || "請輸入正確的電話號碼";
                },
            ],
            pickUpAddressRules: [v => !!v || "地址不得為空", v => v.length >= 8 || "地址過短"],
            areas: [],
            isGetAreas: false,
            area: null,
            isHaveEntity: false,
            isMobile: [],
            infoValid: false,
            submitButtonLoading: false,
            users: [],
        };
    },

    computed: {
        isGetAllInfo() {
            return this.isGetShoppingCarts && this.isGetDeliveries && this.isGetPayments && !this.loading;
        },

        deliveryFee() {
            if (this.deliveries.length === 0) {
                return 0;
            } else {
                return this.delivery.fee;
            }
        },
    },

    mounted() {
        if (this.checkTokenIsValid()) {
            this.getShoppingCarts();
            this.getDeliveries();
            this.getPayments();
            // this.getAreas(); 暫時使用固定地點
            this.isMobile = this.checkIsMobile();
            this.getUserSamePersonalId();
        }
    },

    methods: {
        getShoppingCarts() {
            api.getShoppingCarts()
                .then(response => {
                    if (response.status === 200) {
                        this.shoppingCarts = response.data;
                        // console.log(this.shoppingCarts[0].change_area_id);
                        this.shoppingCarts.forEach(item => {
                            item.subTotal = item.document.price * item.amount;
                        });
                        this.checkIsHaveEntity();
                    } else {
                        this.$toasted.show("獲取購物車內容失敗", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取購物車內容失敗", {
                        type: "error",
                        duration: 2000,
                    });
                });
            this.isGetShoppingCarts = true;
        },

        getDeliveries() {
            api.getDeliveries()
                .then(response => {
                    if (response.status === 200) {
                        this.deliveries = response.data;
                        this.delivery = this.deliveries[0];
                        this.isGetDeliveries = true;
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        getPayments() {
            api.getPayments()
                .then(response => {
                    if (response.status === 200) {
                        this.payments = response.data;
                        this.payment = this.payments[0];
                        this.isGetPayments = true;
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        getAreas() {
            api.getAreas()
                .then(response => {
                    if (response.status === 200) {
                        this.areas = response.data;
                        this.area = this.areas[0];
                        this.isGetAreas = true;
                    } else {
                        this.$toasted.show("獲取資料失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        getUserSamePersonalId() {
            api.getUserSamePersonalId()
                .then(response => {
                    if (response.status === 200) {
                        this.users = response.data;
                        console.log(this.users);
                        this.area = this.users[0].area_id;
                        console.log("user" + this.area);
                    } else {
                        this.$toasted.show("伺服器錯誤", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetUsers = true;
                });
        },

        claculateTotalPrice() {
            let totalPrice = 0;
            this.shoppingCarts.forEach(element => (totalPrice += element.subTotal));
            totalPrice += this.deliveryFee;
            return totalPrice;
        },

        deleteShoppingCart(item, itemIndex) {
            this.loading = true;

            api.deleteShoppingCart({ id: item.id })
                .then(response => {
                    if (response.status === 202) {
                        this.shoppingCarts.splice(itemIndex, 1);
                        this.$toasted.show("刪除成功", {
                            type: "success",
                            duration: 2000,
                        });
                        this.checkIsHaveEntity();
                        this.$refs.orderAppBar.countShoppingCart();
                    } else {
                        this.$toasted.show("刪除失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("刪除失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        checkIsHaveEntity() {
            this.shoppingCarts.forEach(item => {
                if (item.document.is_entity === true) {
                    this.isHaveEntity = true;
                    return;
                }
            });
        },

        createOrder() {
            let deliveryInfo = null;

            //如果是宅配到貨，就要輸入宅配資訊
            if (this.delivery.require_info === true) {
                deliveryInfo = {
                    name: this.pickUpName,
                    phone: this.pickUpPhone,
                    address: this.pickUpAddress,
                    userArea: this.area,
                };
                console.log(this.area);
            }

            // if (this.infoValid) {
            this.submitButtonLoading = true;
            if (this.shoppingCarts[0].change_area_id == 0) {
                this.area = this.area.id;
            }
            api.createOrder({
                delivery_id: this.isHaveEntity === true ? this.delivery.id : null,
                payment_id: this.payment.id,
                delivery_info: deliveryInfo,
                area_id: this.area, //暫時修改為固定校區
                // area_id: this.area.id,
                remark: this.remark,
            })
                .then(response => {
                    //檢查付款方式
                    console.log(response);
                    if (response.status === 201) {
                        this.$toasted.show("送出訂單成功。", {
                            type: "success",
                            duration: 2000,
                        });
                        switch (this.payment.id) {
                            case 1: {
                                let linePayRequestURL = response.data["info"]["paymentUrl"]["web"];
                                window.location.href = linePayRequestURL;
                                break;
                            }
                            case 2:
                                this.$router.replace("/application-record");
                                break;
                            default:
                                this.$router.replace("/application-record");
                                break;
                        }
                    } else {
                        this.$toasted.show("送出訂單失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                // .catch(() => {
                //     this.$toasted.show("送出訂單失敗，請稍後再試。", {
                //         type: "error",
                //         duration: 2000,
                //     });
                // })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
            // }
        },

        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 20;
        },

        toTop() {
            this.$vuetify.goTo(0);
        },

        checkIsMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            return flag;
        },

        checkTokenIsValid() {
            let token = this.$cookies.get("Authorization");

            if (!token) {
                this.isCheckingTokenValid = false;
                this.$router.replace("/login");
                return false;
            }

            return true;
        },
    },
};
</script>

<style scoped>
.col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.shopping-cart-container {
    width: 70%;
    min-width: 300px;
    border: 2px solid #000000;
    margin-bottom: 50px;
}

.same-category-each-cell {
    border-right: 1px solid #efefef;
}

.container-header {
    background-color: #dedede;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}

.different-category-row {
    border-bottom: 2px solid #000000;
}

@media screen and (max-width: 600px) {
    .shopping-cart-container {
        width: 100%;
    }
}
</style>
