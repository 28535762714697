<template>
    <div>
        <v-overlay :value="!isGetAllInfo">
            <v-progress-circular :indeterminate="!isGetAllInfo" color="primary"></v-progress-circular>
        </v-overlay>
        <adminAppBar></adminAppBar>
        <v-container class="mt-10">
            <v-row class="different-category-row">
                <v-col cols="2" class="container-header same-category-each-cell">申請文件</v-col>
                <v-col cols="8">
                    <documentClassTabs :pageActions="pageActions" :documentClasses="documentClasses" :accounts="accounts" @getDocumentClasses="getDocumentClasses" style="width: 100%"></documentClassTabs>
                </v-col>
                <v-col cols="2">
                    <createDocumentClassDialog v-if="pageActions.data_setting_document_class_add" @getDocumentClasses="getDocumentClasses"></createDocumentClassDialog>
                </v-col>
            </v-row>
            <v-row class="different-category-row">
                <v-col cols="2" class="container-header same-category-each-cell">取貨方式</v-col>
                <v-col class="d-flex flex-wrap justify-start">
                    <modifyDeliveryDialog v-for="(delivery, index) in deliveries" :key="index" :pageActions="pageActions" :delivery="delivery" @deleteDelivery="deleteDelivery" @getDeliveries="getDeliveries"></modifyDeliveryDialog>
                </v-col>
                <v-col cols="2">
                    <createDeliveryDialog v-if="pageActions.data_setting_delivery_add" @getDeliveries="getDeliveries"></createDeliveryDialog>
                </v-col>
            </v-row>
            <v-row class="different-category-row">
                <v-col cols="2" class="container-header same-category-each-cell align-center">付款方式</v-col>
                <v-col class="d-flex flex-wrap justify-start">
                    <modifyPaymentDialog v-for="(payment, index) in payments" :key="index" :pageActions="pageActions" :payment="payment" @deletePayment="deletePayment"></modifyPaymentDialog>
                </v-col>
                <v-col cols="2">
                    <createPaymentDialog v-if="pageActions.data_setting_payment_add" @getPayments="getPayments"></createPaymentDialog>
                </v-col>
            </v-row>
            <v-row class="different-category-row">
                <v-col cols="2" class="container-header same-category-each-cell">學年度顯示</v-col>
                <v-col class="d-flex flex-wrap justify-start">
                    <modifyAcademicYearDialog v-for="(academicYear, index) in academicYears" :key="index" :pageActions="pageActions" :academicYear="academicYear" @deleteAcademicYear="deleteAcademicYear"></modifyAcademicYearDialog>
                </v-col>
                <v-col cols="2">
                    <createAcademicYearsDialog v-if="pageActions.data_setting_academic_year_add" @getAcademicYears="getAcademicYears"></createAcademicYearsDialog>
                </v-col>
            </v-row>
            <v-row class="different-category-row">
                <v-col cols="2" class="container-header same-category-each-cell">公告</v-col>
                <v-col class="d-flex flex-wrap justify-start">
                    <modifyAnnouncementDialog v-for="(announcement, index) in announcements" :key="index" :pageActions="pageActions" :announcement="announcement" @deleteAnnouncement="deleteAnnouncement"></modifyAnnouncementDialog>
                </v-col>
                <v-col cols="2">
                    <createAnnouncementDialog v-if="pageActions.data_setting_announcement_add" @getAnnouncements="getAnnouncements"></createAnnouncementDialog>
                </v-col>
            </v-row>
            <v-row class="different-category-row">
                <v-col cols="2" class="container-header same-category-each-cell">校區</v-col>
                <v-col class="d-flex flex-wrap justify-start">
                    <modifyAreaDialog v-for="(area, index) in areas" :key="index" :pageActions="pageActions" :area="area" @deleteArea="deleteArea"></modifyAreaDialog>
                </v-col>
                <v-col cols="2">
                    <createAreaDialog v-if="pageActions.data_setting_area_add" @getAreas="getAreas"></createAreaDialog>
                </v-col>
            </v-row>
            <v-row class="different-category-row">
                <v-col cols="2" class="container-header same-category-each-cell">部門</v-col>
                <v-col class="d-flex flex-wrap justify-start">
                    <modifyAdminDepartmentDialog v-for="(admindepart, index) in admindeparts" :key="index" :pageActions="pageActions" :admindepart="admindepart" @deleteAdmindepart="deleteAdmindepart"></modifyAdminDepartmentDialog>
                </v-col>
                <v-col cols="2">
                    <createAdminDepartmentDialog v-if="pageActions.data_setting_area_add" @getAreas="getAreas"></createAdminDepartmentDialog>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2" class="container-header same-category-each-cell">會計科目</v-col>
                <v-col class="d-flex flex-wrap justify-start">
                    <modifyAccountDialog v-for="(account, index) in accounts" :key="index" :pageActions="pageActions" :account="account" @deleteAccount="deleteAccount"></modifyAccountDialog>
                </v-col>
                <v-col cols="2">
                    <createAccountDialog v-if="pageActions.data_setting_account_add" @getAccounts="getAccounts"></createAccountDialog>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import api from "../api/api.js";
import adminAppBar from "../components/AdminAppBar.vue";
import documentClassTabs from "../components/DocumentClassTabs.vue";
import createDocumentClassDialog from "../components/CreateDocumentClassDialog.vue";
import modifyDeliveryDialog from "../components/ModifyDeliveryDialog.vue";
import createDeliveryDialog from "../components/CreateDeliveryDialog.vue";
import modifyPaymentDialog from "../components/ModifyPaymentDialog.vue";
import createPaymentDialog from "../components/CreatePaymentDialog.vue";
import modifyAcademicYearDialog from "../components/ModifyAcademicYearDialog.vue";
import createAcademicYearsDialog from "../components/CreateAcademicYearDialog.vue";
import modifyAnnouncementDialog from "../components/ModifyAnnouncementDialog.vue";
import createAnnouncementDialog from "../components/CreateAnnouncementDialog.vue";
import modifyAreaDialog from "../components/ModifyAreaDialog.vue";
import createAreaDialog from "../components/CreateAreaDialog.vue";
import modifyAccountDialog from "../components/ModifyAccountDialog.vue";
import createAccountDialog from "../components/CreateAccountDialog.vue";
import createAdminDepartmentDialog from "../components/CreateAdminDepartmentDialog.vue";
import modifyAdminDepartmentDialog from "../components/ModifyAdminDepartmentDialog.vue";

export default {
    name: "ManagementSetting",

    components: {
        adminAppBar,
        documentClassTabs,
        createDocumentClassDialog,
        modifyDeliveryDialog,
        createDeliveryDialog,
        modifyPaymentDialog,
        createPaymentDialog,
        modifyAcademicYearDialog,
        createAcademicYearsDialog,
        modifyAnnouncementDialog,
        createAnnouncementDialog,
        modifyAreaDialog,
        createAreaDialog,
        modifyAccountDialog,
        createAccountDialog,
        createAdminDepartmentDialog,
        modifyAdminDepartmentDialog,
    },

    data() {
        return {
            documentClasses: [],
            deliveries: [],
            payments: [],
            academicYears: [],
            announcements: [],
            areas: [],
            admindeparts: [],
            accounts: [],
            isGetdocumentClasses: false,
            isGetdeliveries: false,
            isGetPayments: false,
            isGetAcademicYears: false,
            isGetAnnouncements: false,
            isGetAreas: false,
            isGetAdmindepart: false,
            isGetAccounts: false,
            pageActions: {},
            pageActionList: ["data_setting_document_class_add", "data_setting_document_class_edit", "data_setting_document_class_delete", "data_setting_document_add", "data_setting_document_edit", "data_setting_document_delete", "data_setting_delivery_add", "data_setting_delivery_edit", "data_setting_delivery_delete", "data_setting_payment_add", "data_setting_payment_edit", "data_setting_payment_delete", "data_setting_progress_add", "data_setting_progress_edit", "data_setting_progress_delete", "data_setting_academic_year_add", "data_setting_academic_year_edit", "data_setting_academic_year_delete", "data_setting_announcement_add", "data_setting_announcement_edit", "data_setting_announcement_delete", "data_setting_area_add", "data_setting_area_edit", "data_setting_area_delete", "data_setting_account_add", "data_setting_account_edit", "data_setting_account_delete"],
        };
    },

    computed: {
        isGetAllInfo() {
            return this.isGetdocumentClasses && this.isGetdeliveries && this.isGetPayments && this.isGetAcademicYears && this.isGetAnnouncements && this.isGetAreas && this.isGetAccounts && this.isGetAdmindepart;
        },
    },

    mounted() {
        this.checkKeyIsValid("data_setting");
        this.checkKeyIsValidForComponent();

        if (this.checkTokenIsValid() === true) {
            this.getDocumentClasses();
            this.getDeliveries();
            this.getPayments();
            this.getAcademicYears();
            this.getAnnouncements();
            this.getAreas();
            this.getAccounts();
            this.getAdminDepartmentGroup();
        } else {
            this.$router.push("/admin");
        }
    },

    methods: {
        getDocumentClasses() {
            this.isGetdocumentClasses = false;
            api.getDocumentClasses()
                .then(response => {
                    this.documentClasses = response.data;
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetdocumentClasses = true;
                });
        },

        getDeliveries() {
            this.isGetdeliveries = false;
            api.getDeliveries()
                .then(response => {
                    this.deliveries = response.data;
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetdeliveries = true;
                });
        },

        deleteDelivery(delivery) {
            this.deliveries.splice(this.deliveries.indexOf(delivery), 1);
        },

        getPayments() {
            this.isGetPayments = false;
            api.getPayments()
                .then(response => {
                    this.payments = response.data;
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetPayments = true;
                });
        },

        deletePayment(payment) {
            this.payments.splice(this.payments.indexOf(payment), 1);
        },

        getAcademicYears() {
            api.getAcademicYears()
                .then(response => {
                    this.academicYears = response.data;
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetAcademicYears = true;
                });
        },

        deleteAcademicYear(academicYear) {
            this.academicYears.splice(this.academicYears.indexOf(academicYear), 1);
        },

        getAnnouncements() {
            this.isGetAnnouncements = false;
            api.getAnnouncements()
                .then(response => {
                    this.announcements = response.data;
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetAnnouncements = true;
                });
        },

        deleteAnnouncement(announcement) {
            this.announcements.splice(this.announcements.indexOf(announcement), 1);
        },

        getAreas() {
            this.isGetAreas = false;
            api.getAreas()
                .then(response => {
                    this.areas = response.data;
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetAreas = true;
                });
        },

        getAdminDepartmentGroup() {
            this.isGetAdmindepart = false;
            api.getAdminDepartmentGroup()
                .then(response => {
                    this.admindeparts = response.data;
                    console.log(this.admindeparts);
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetAdmindepart = true;
                });
        },

        deleteArea(area) {
            this.areas.splice(this.areas.indexOf(area), 1);
        },

        deleteAdmindepart(admindepart) {
            this.admindeparts.splice(this.admindeparts.indexOf(admindepart), 1);
        },

        getAccounts() {
            this.isGetAccounts = false;
            api.getAccounts()
                .then(response => {
                    this.accounts = response.data;
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetAccounts = true;
                });
        },

        checkKeyIsValidForComponent() {
            let key = this.$cookies.get("key");

            this.pageActionList.forEach(element => {
                if (key.indexOf(element) == -1) {
                    this.$set(this.pageActions, element, false);
                } else {
                    this.$set(this.pageActions, element, true);
                }
            });
        },

        checkKeyIsValid(keyTarget) {
            let key = this.$cookies.get("key");

            if (key.indexOf(keyTarget) == -1) {
                alert("您無權限。");
                this.$router.go(-1);
            }
        },

        checkTokenIsValid() {
            let token = this.$cookies.get("Authorization");

            if (!token) {
                this.isCheckingTokenValid = false;
                this.$router.replace("/admin");
                return false;
            }

            return true;
        },
    },
};
</script>

<style scoped>
.col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.container {
    width: 80%;
    border: 2px solid #000000;
    min-width: 300px;
}

.same-category-each-cell {
    border-right: 1px solid #000000;
}

.container-header {
    background-color: #dedede;
}

.different-category-row {
    border-bottom: 2px solid #000000;
}

@media screen and (max-width: 600px) {
    .container {
        width: 100%;
    }
}
</style>
