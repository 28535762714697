<template>
    <div>
        <ordererAppBar></ordererAppBar>
        <v-overlay :value="!isGetUsers">
            <v-progress-circular :indeterminate="isGetUsers" color="primary"></v-progress-circular>
        </v-overlay>
        <h1 class="mt-10">文件申請系統</h1>
        <!-- <p>姓名：{{ users[0].name }} &nbsp;生日：{{ users[0].birthday }}&nbsp;身分證字號：{{ users[0].personal_id }}&nbsp;連絡電話：{{ users[0].phone }}&nbsp;Email：{{ users[0].email }}</p> -->
        <v-card outlined class="mx-auto my-12" max-width="1500">
            <v-row class="justify-center">
                <v-col cols="9">
                    <v-data-table :headers="user_headers" :items="user_item" hide-default-footer class="elevation-1">
                        <template v-slot:item.phone="{ item }">
                            <p v-if="!isEdit" class="ma-0">{{ item.phone }}</p>
                            <v-text-field v-if="isEdit" v-model="personalphone" outlined hide-details="auto" dense></v-text-field>
                        </template>
                        <template v-slot:item.personalEmail="{ item }">
                            <p v-if="!isEdit" class="ma-0">{{ item.personalEmail }}</p>
                            <v-text-field v-if="isEdit" v-model="personalemail" outlined hide-details="auto" dense></v-text-field>
                        </template>
                        <template v-slot:item.reset="{}">
                            <v-btn v-if="!isEdit" color="primary" @click="isEdit = true">修改</v-btn>
                            <v-btn v-if="isEdit" color="success" @click="createUserEmail(users[0].account)">完成</v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-col></v-col>
            <v-expansion-panels>
                <v-expansion-panel v-for="(user, index) in users" :key="index">
                    <v-expansion-panel-header>
                        <v-col cols="3" lg="4" class="same-category-each-cell" align-self="center">學制：{{ user.class_name }}</v-col>
                        <v-col cols="3" lg="4" class="same-category-each-cell" align-self="center">學號：{{ user.account }}</v-col>
                        <template v-slot:actions>
                            <v-btn color="primary" @click="chooseAcademicSystem(user)">進行申請</v-btn>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container class="pb-0">
                            <v-row>
                                <v-col cols="3" lg="4" class="same-category-each-cell" align-self="center">校區：{{ user.area }}</v-col>
                                <v-col cols="3" lg="4" class="same-category-each-cell" align-self="center">系所：{{ user.department }}</v-col>
                                <v-col cols="3" lg="4" class="same-category-each-cell" align-self="center">班級：{{ user.class_name }}</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3" lg="4" class="same-category-each-cell" align-self="center">學位：{{ user.degree }}</v-col>
                                <v-col cols="3" lg="4" class="same-category-each-cell" align-self="center">狀態：{{ user.graduate }}</v-col>
                            </v-row>
                        </v-container>
                        <!-- <p>&nbsp;&nbsp;&nbsp;</p> -->
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
    </div>
</template>
<script>
import api from "../api/api.js";
import ordererAppBar from "../components/OrdererAppBar.vue";

export default {
    name: "AcademicSystemChoose",

    components: {
        ordererAppBar,
    },

    data() {
        return {
            users: [],
            personalemail: "",
            personalphone: "",
            isGetUsers: false,
            isEdit: false,
            user_headers: [
                { text: "姓名", value: "name" },
                { text: "生日", value: "birthday" },
                { text: "身分證字號", value: "personalId" },
                { text: "連絡電話", value: "phone" },
                { text: "Email", value: "email" },
                { text: "個人Email", value: "personalEmail" },
                { text: "", value: "reset" },
            ],
            user_item: [
                {
                    name: "",
                    birthday: "",
                    personalId: "",
                    phone: "",
                    email: "",
                    personalEmail: "",
                },
            ],
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.getUserSamePersonalId();
        });
    },

    methods: {
        getUserSamePersonalId() {
            this.isGetUsers = false;

            api.getUserSamePersonalId()
                .then(response => {
                    if (response.status === 200) {
                        this.users = response.data;
                        this.user_item[0].name = this.users[0].name;
                        this.user_item[0].birthday = this.users[0].birthday;
                        this.user_item[0].personalId = this.users[0].personal_id;
                        this.user_item[0].phone = this.users[0].useremail.phone;
                        this.user_item[0].email = this.users[0].email;
                        this.user_item[0].personalEmail = this.users[0].useremail.email;
                        this.personalemail = this.users[0].useremail.email;
                        this.personalphone = this.users[0].useremail.phone;
                        if (this.users.length < 2) {
                            this.$cookies.set("Authorization", "Bearer " + this.users[0]["token"], "1d");
                            // this.$router.replace("/");
                        }
                    } else {
                        this.$toasted.show("伺服器錯誤", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetUsers = true;
                });
        },

        createUserEmail(account) {
            this.isEdit = false;
            api.createUserEmail({
                account: account,
                email: this.personalemail,
                phone: this.personalphone,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.$toasted.show("修改成功", {
                            type: "success",
                            duration: 2000,
                        });
                        this.getUserSamePersonalId();
                    } else {
                        this.$toasted.show("修改失敗，請稍後再試", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("修改失敗，請稍後再試", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetUsers = true;
                });
        },

        chooseAcademicSystem(user) {
            this.$cookies.set("Authorization", "Bearer " + user.token, "1d");
            this.$router.replace("/");
        },
    },
};
</script>
<style scoped>
.v-expansion-panels {
    width: 50%;
    margin: 0 auto;
}
</style>
