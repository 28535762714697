<template>
    <div>
        <v-dialog v-model="isShowDialog" width="500px">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="success" v-bind="attrs" v-on="on"> 選購 </v-btn>
            </template>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> {{ document.name }} </v-card-title>
                <v-form ref="form" v-model="isFormValid" @submit.prevent="isFormValid && createShoppingCart()">
                    <v-container class="pb-0">
                        <v-row class="my-0">
                            <v-col> 金額：$ {{ document.price }}</v-col>
                        </v-row>
                        <v-row class="my-0">
                            <v-col>
                                <span>數量：</span>
                                <v-select v-if="document.id !== 10 && document.id !== 7 && document.id !== 3" v-model="amount" :items="amountEnum" outlined dense hide-details="auto"></v-select>
                                <v-select v-if="document.id === 3" v-model="amount" :items="amountEnum" outlined dense hide-details="auto" @change="checkEnScore"></v-select>
                                <v-select v-if="document.id == 7" v-model="amount" :items="1" outlined dense hide-details="auto"></v-select>
                                <span v-if="document.id === 10">{{ amount }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="my-0" v-if="document.semester_require">
                            <v-col>
                                <span style="width: 70px">學年：</span>
                                <v-text-field v-model="academicYear" :rules="academicYearRules" outlined dense hide-details="auto"></v-text-field>
                                <!-- <v-select :items="academicYears" item-text="name" item-value="name"  outlined  required></v-select> -->
                            </v-col>
                            <v-col>
                                <span style="width: 70px">學期：</span>
                                <!-- <v-select v-model="semester" :items="semesters" :rules="semesterRules" outlined dense hide-details="auto" required></v-select> -->
                                <v-text-field v-model="semester" :rules="semesterRules" outlined dense hide-details="auto"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="my-0" v-if="document.id === 1 || document.id === 2">
                            <v-col>
                                <v-checkbox v-model="isContainRankAndPercentage" label="含名次、百分比(rank in class, percentage)" class="mt-0 pt-0" hide-details="auto"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row v-if="document.id === 4 || document.id === 7" class="my-0">
                            <v-col class="py-1">
                                <span>※僅限在學生，並確認該學期已繳費。</span>
                            </v-col>
                        </v-row>
                        <v-row v-if="document.id === 7" class="my-0">
                            <!-- <v-col class="py-1">
                                <v-checkbox v-model="isConrtainHistoryScoreRank" label="歷年成績名次" class="mt-0 pt-0" hide-details="auto" @change="changeCheckBox"></v-checkbox>
                            </v-col> -->
                        </v-row>
                        <v-row v-if="document.id === 7" class="my-0">
                            <v-col class="py-1">
                                <v-radio-group v-model="radioGroup">
                                    <v-radio :value="n">
                                        <template v-slot:label>
                                            <label class="mt-0 pt-0">歷年成績名次</label>
                                        </template>
                                    </v-radio>
                                    <v-radio :value="n">
                                        <template v-slot:label>
                                            <v-row class="align-center ml-2">
                                                <label class="mt-0 pt-0">學年：</label>
                                                <v-col class="mt-0 pt-0" cols="3">
                                                    <v-select v-model="SelectGrade" :items="grade" label="" dense solo hide-details></v-select>
                                                </v-col>
                                                <label class="mt-0 pt-0">學期：</label>
                                                <v-col class="mt-0 pt-0" cols="3">
                                                    <v-select v-model="SelectTerm" :items="term" label="" dense solo hide-details></v-select>
                                                </v-col>
                                                <label class="mt-0 pt-0">名次</label>
                                            </v-row>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <!-- <v-checkbox v-model="isConrtainGradeScoreRank" class="mt-0 pt-0" hide-details="auto" @change="changeCheckBox"></v-checkbox>
                                <p class="mt-0 pt-0">學年：</p> -->
                            </v-col>
                            <!-- <v-col cols="8">
                                <v-col></v-col>
                            </v-col> -->
                        </v-row>
                        <v-row v-if="document.id === 3 || document.id === 4 || document.id === 6" class="my-0">
                            <v-col class="py-1">
                                <span>英文名字(需與護照相同)：</span>
                                <v-text-field v-model="englishName" :rules="englishNameRules" outlined hide-details="auto" dense required maxlength="100"></v-text-field>
                            </v-col>
                        </v-row>
                        <div v-if="document.id === 3">
                            <v-row class="my-0">
                                <v-col class="py-1">
                                    <v-checkbox v-model="isNoteGPA" label="備註GPA" class="mt-0 pt-0" hide-details="auto"></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row class="my-0">
                                <v-col class="py-1">
                                    <span>成績表示法(Records)：</span>
                                </v-col>
                            </v-row>
                            <v-row class="my-0" align="center">
                                <v-col class="py-1">
                                    <span>等級(by grade)</span>
                                    <div class="px-1" style="width: 70px">
                                        <v-text-field v-model.number="englishLevelAmount" :max="maxEnglishLevelAmount" type="number" outlined hide-details="auto" dense required min="0" @change="checkEnScore"></v-text-field>
                                    </div>
                                    <span>份(copies)</span>
                                </v-col>
                            </v-row>
                            <v-row class="my-0" align="center">
                                <v-col class="py-1">
                                    <span>分數(by score)</span>
                                    <div class="px-1" style="width: 70px">
                                        <v-text-field v-model.number="englishLevelScore" :max="maxEnglishLevelScore" type="number" outlined hide-details="auto" dense required min="0" @change="checkEnScore"></v-text-field>
                                    </div>
                                    <span>份(copies)</span>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row class="ma-0 align-end" v-if="document.id === 5">
                            <v-radio-group v-model="replacementOfChineseCertificateOfGraduationReason" hide-details="auto" required>
                                <v-radio v-for="(reason, index) in replacementOfChineseCertificateOfGraduationReasons" :key="index" :label="reason" :value="reason"></v-radio>
                            </v-radio-group>
                            <v-text-field v-model="replacementOfChineseCertificateOfGraduationActurallyReason" :disabled="replacementOfChineseCertificateOfGraduationReason !== '其他：'" dense outlined hide-details="auto"></v-text-field>
                        </v-row>
                        <v-row class="ma-0" v-if="document.id === 5">
                            <span style="text-align: left">※須附身分證正反面影本(A photocopy of the Id Card-two sides is required.)</span>
                            <span style="text-align: left">※本人確實遺失學位證書，如有不實，願負一切法律責任。</span>
                            <span style="text-align: left">※請印出申請單後簽名。</span>
                            <v-btn target="_blank" color="success" href="https://pay.nkust.edu.tw:9999/F-2-51.pdf">列印申請單</v-btn>
                        </v-row>
                        <v-row class="ma-0 align-end" v-if="document.id === 10">
                            <v-radio-group v-model="replacementIdCardReason" hide-details="auto" required>
                                <v-radio v-for="(reason, index) in replacementIdCardReasons" :key="index" :label="reason" :value="reason"></v-radio>
                            </v-radio-group>
                            <v-text-field v-model="replacementIdCardActurallyReason" :disabled="replacementIdCardReason !== '其他：'" dense outlined hide-details="auto"></v-text-field>
                        </v-row>
                        <v-row class="ma-0" v-if="document.id === 10">
                            <span style="text-align: left">※需先至「學生證卡務系統掛失平台」辦理掛失手續。</span>
                            <a target="_blank" href="http://140.133.78.34/std/index.php/user/login">(http://140.133.78.34/std/index.php/user/login)</a>
                        </v-row>
                        <v-row class="my-0" v-if="document.remark_valid === true">
                            <v-col class="py-1">
                                <span>備註：</span>
                                <v-textarea v-model="remark" outlined hide-details="auto" rows="3" no-resize maxlength="100"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="my-0">
                            <v-col>
                                <v-btn color="success" @click="createShoppingCart" block :loading="submitButtonLoading" :disabled="!sendBtn">加入購物車(${{ amount * document.price }})</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "../api/api.js";

export default {
    name: "CreateShoppingCartDialog",

    props: {
        document: Object,
        academicYears: Array,
    },

    data() {
        return {
            isShowDialog: false,
            isFormValid: false,
            sendBtn: true,
            amountEnum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            amount: 1,
            academicYear: "",
            academicYearRules: [v => !!v || "學年不得為空"],
            semesters: [1, 2],
            semester: "",
            semesterRules: [v => !!v || "學期不得為空"],
            remark: "",
            isContainRankAndPercentage: false,
            englishName: "",
            englishNameRules: [v => !!v || "英文名字不得為空"],
            isNoteGPA: false,
            englishLevelAmount: 0,
            englishLevelScore: 0,
            maxEnglishLevelAmount: 1,
            maxEnglishLevelScore: 1,
            replacementOfChineseCertificateOfGraduationReasons: ["毀損(檢具舊證書)", "遺失", "其他："],
            replacementOfChineseCertificateOfGraduationReason: "",
            replacementOfChineseCertificateOfGraduationActurallyReason: "",
            replacementIdCardReasons: ["遺失", "毀損", "更名", "其他："],
            replacementIdCardReason: "",
            replacementIdCardActurallyReason: "",
            isConrtainHistoryScoreRank: false,
            isConrtainGradeScoreRank: false,
            radioGroup: -1,
            submitButtonLoading: false,
            grade: [],
            term: [1, 2],
            today: new Date(),
            cyear: 0,
            SelectGrade: "",
            SelectTerm: "",
        };
    },
    mounted() {
        this.radioGroup = -1;
        this.cyear = parseInt(this.today.getFullYear() - 1911);
        for (var i = 52; i <= this.cyear; i++) {
            this.grade.push(i);
        }
        this.checkEnScore;
    },
    watch: {
        isShowDialog(val) {
            if (val === false) {
                this.resetForm();
            }
        },
    },

    methods: {
        createShoppingCart() {
            if (this.isFormValid) {
                let remarkTemp = "";

                if (this.academicYear !== "" && this.semester != "") {
                    remarkTemp = this.academicYear + "，" + this.semester;
                }
                console.log(this.academicYear, this.semester);
                if (this.englishName !== "") {
                    if (remarkTemp !== "") {
                        remarkTemp += "，";
                    }
                    remarkTemp += this.englishName;
                }

                if (this.isContainRankAndPercentage === true) {
                    if (remarkTemp !== "") {
                        remarkTemp += "，";
                    }
                    remarkTemp += "含名次、百分比";
                }

                if (this.radioGroup === 0) {
                    if (remarkTemp !== "") {
                        remarkTemp += "，";
                    }
                    remarkTemp += "含歷年成績名次";
                }

                if (this.radioGroup === 1) {
                    if (remarkTemp !== "") {
                        remarkTemp += "，";
                    }
                    remarkTemp += "含" + this.SelectGrade + "學年度，第" + this.SelectTerm + "學期名次";
                }

                if (this.isNoteGPA === true) {
                    if (remarkTemp !== "") {
                        remarkTemp += "，";
                    }
                    remarkTemp += "備註GPA";
                }

                if (this.englishLevelAmount > 0) {
                    if (remarkTemp !== "") {
                        remarkTemp += "，";
                    }
                    remarkTemp += "等級" + this.englishLevelAmount + "份";
                }

                if (this.englishLevelScore > 0) {
                    if (remarkTemp !== "") {
                        remarkTemp += "，";
                    }
                    remarkTemp += "分數" + this.englishLevelScore + "份";
                }

                if (this.replacementOfChineseCertificateOfGraduationReason != "") {
                    if (remarkTemp !== "") {
                        remarkTemp += "，";
                    }
                    if (this.replacementOfChineseCertificateOfGraduationReason != "其他：") {
                        remarkTemp += this.replacementOfChineseCertificateOfGraduationReason;
                    } else {
                        remarkTemp += this.replacementOfChineseCertificateOfGraduationActurallyReason;
                    }
                }

                if (this.remark !== "") {
                    if (remarkTemp !== "") {
                        remarkTemp += "，";
                    }
                    remarkTemp += this.remark;
                }

                this.submitButtonLoading = true;

                api.createShoppingCart({
                    document_id: this.document.id,
                    amount: this.amount,
                    change_area_id: this.document.change_area,
                    remark: remarkTemp,
                })
                    .then(response => {
                        if (response.status === 201) {
                            this.isShowDialog = false;
                            this.resetForm();

                            this.$toasted.show("新增成功。", {
                                type: "success",
                                duration: 2000,
                            });
                            this.$emit("countShopping");
                        } else {
                            this.$toasted.show("新增失敗，請稍後再試。", {
                                type: "error",
                                duration: 2000,
                            });
                        }
                    })
                    .catch(() => {
                        this.$toasted.show("新增失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    })
                    .finally(() => {
                        this.submitButtonLoading = false;
                    });
            }
        },

        resetForm() {
            this.$refs.form.resetValidation();
            this.amount = 1;
            this.remark = "";
        },

        checkEnScore() {
            if (this.englishLevelScore + this.englishLevelAmount > this.amount) {
                this.englishLevelAmount = 0;
                this.englishLevelScore = 0;
            }
            this.maxEnglishLevelScore = this.amount - this.englishLevelAmount;
            this.maxEnglishLevelAmount = this.amount - this.englishLevelScore;
        },
    },
};
</script>

<style scoped>
.col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>
