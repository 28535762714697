<template>
    <div>
        <v-dialog v-model="isShowDialog">
            <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn color="success" v-bind="attrs" v-on="on"> 新增文件 </v-btn>
            </template> -->
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> 新增文件 </v-card-title>
                <v-container>
                    <v-form ref="form" v-model="isFormValid" @submit.prevent="isFormValid && createDocument()">
                        <v-row class="each-item-row container-header">
                            <v-col cols="4" class="same-category-each-cell">文件名稱</v-col>
                            <v-col cols="4" class="same-category-each-cell">ecnt</v-col>
                            <v-col cols="2" class="same-category-each-cell">會計科目</v-col>
                            <v-col cols="2" class="same-category-each-cell">金額</v-col>
                        </v-row>
                        <v-row class="different-category-row">
                            <v-col cols="4">
                                <v-text-field v-model="name" type="text" :rules="nameRules" outlined hide-details="auto" dense required autofocus maxlength="100"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-model="ecnt" type="text" outlined hide-details="auto" dense required maxlength="200"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-select v-model="accountId" :items="accounts" item-text="number" item-value="id" outlined hide-details="auto" dense required></v-select>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field v-model.number="price" type="number" outlined hide-details="auto" dense required min="0" max="9999"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="each-item-row container-header">
                            <v-col cols="1" class="same-category-each-cell">顯示</v-col>
                            <v-col cols="1" class="same-category-each-cell">開放備註</v-col>
                            <v-col cols="1" class="same-category-each-cell">學期選擇</v-col>
                            <v-col cols="1" class="same-category-each-cell">實體</v-col>
                            <v-col cols="1" class="same-category-each-cell">在校生申請</v-col>
                            <v-col cols="1" class="same-category-each-cell">非在校生申請</v-col>
                            <v-col cols="2" class="same-category-each-cell">選擇承辦處室</v-col>
                            <v-col cols="2" class="same-category-each-cell">備註</v-col>
                            <v-col cols="2">操作</v-col>
                        </v-row>
                        <v-row class="different-category-row">
                            <v-col cols="1">
                                <v-checkbox v-model="valid" hide-details="auto" dense></v-checkbox>
                            </v-col>
                            <v-col cols="1">
                                <v-checkbox v-model="remarkValid" hide-details="auto" dense></v-checkbox>
                            </v-col>
                            <v-col cols="1">
                                <v-checkbox v-model="semesterRequire" hide-details="auto" dense></v-checkbox>
                            </v-col>
                            <v-col cols="1">
                                <v-checkbox v-model="isEntity" hide-details="auto" dense></v-checkbox>
                            </v-col>
                            <v-col cols="1">
                                <v-checkbox v-model="isForMember" hide-details="auto" dense></v-checkbox>
                            </v-col>
                            <v-col cols="1">
                                <v-checkbox v-model="isForAlumni" hide-details="auto" dense></v-checkbox>
                            </v-col>
                            <v-col cols="2">
                                <v-select v-model="departmentAssign" :items="department" label="選擇承辦處室" dense solo hide-details></v-select>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field v-model="remark" type="text" outlined hide-details="auto" dense required></v-text-field>
                            </v-col>
                            <v-col cols="2" class="pa-0">
                                <v-btn type="submit" color="success" :loading="submitButtonLoading">新增</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "../api/api.js";

export default {
    name: "CreatedocumentDialog",

    data() {
        return {
            isShowDialog: false,
            isFormValid: false,
            documentClass: [],
            accounts: [],
            name: "",
            ecnt: "",
            accountId: 0,
            price: 0,
            isForMember: false,
            isForAlumni: false,
            isEntity: false,
            valid: false,
            remarkValid: false,
            semesterRequire: false,
            departmentAssign: "",
            remark: "",
            nameRules: [v => !!v || "必填"],
            submitButtonLoading: false,
            department: [],
        };
    },

    watch: {
        isShowDialog(val) {
            if (val === false) {
                this.resetForm();
            }
        },
    },
    mounted() {},

    methods: {
        init(documentClass, accounts) {
            console.log(documentClass);
            console.log(accounts);
            this.isShowDialog = true;
            this.getAdminDepartment();
            this.documentClass = documentClass;
            this.accounts = accounts;
        },
        createDocument() {
            this.submitButtonLoading = true;

            api.createDocument({
                document_class_id: this.documentClass.id,
                name: this.name,
                ecnt: this.ecnt,
                account_id: this.accountId,
                price: this.price,
                valid: this.valid,
                is_for_member: this.isForMember,
                is_for_alumni: this.isForAlumni,
                is_entity: this.isEntity,
                remark_valid: this.remarkValid,
                semester_require: this.semesterRequire,
                departmentAssign: this.departmentAssign,
                remark: this.remark,
            })
                .then(response => {
                    if (response.status === 201) {
                        this.isShowDialog = false;
                        this.resetForm();
                        this.$emit("getDocumentClasses");

                        this.$toasted.show("新增成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("新增失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("新增失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },
        getAdminDepartment() {
            api.getAdminDepartment()
                .then(response => {
                    this.department = response.data;
                })
                .catch(() => {
                    this.$toasted.show("新增失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },
        resetForm() {
            this.$refs.form.resetValidation();
            this.name = "";
            this.ecnt = "";
            this.accountId = 0;
            this.price = 0;
            this.valid = false;
            this.isForMember = false;
            this.isForAlumni = false;
            this.isEntity = false;
            this.remarkValid = false;
            this.semesterRequire = false;
            this.departmentAssign = "";
            this.remark = "";
        },
    },
};
</script>

<style scoped>
.v-btn {
    margin-right: 10px;
    margin-left: 10px;
}

.container {
    border: 2px solid #000000;
}

.col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.same-category-each-cell {
    border-right: 1px solid #efefef;
}

.container-header {
    background-color: #dedede;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}

.different-category-row {
    border-bottom: 2px solid #000000;
}
</style>
