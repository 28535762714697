<template>
    <div>
        <ordererVisitorAppBar></ordererVisitorAppBar>
        <v-overlay :value="isCheckingTokenValid">
            <v-progress-circular :indeterminate="isCheckingTokenValid" color="primary"></v-progress-circular>
        </v-overlay>
        <h1 class="mt-10">文件申請系統</h1>
        <h3>請輸入帳號(學生身分證字號)、密碼：</h3>
        <v-form v-model="infoValid" class="mb-10">
            <v-container style="min-width: 300px">
                <v-row>
                    <v-col cols="3" class="text-h6" align-self="center">帳號</v-col>
                    <v-col cols="9">
                        <v-text-field v-model="account" :rules="accountRules" outlined autofocus required hide-details="auto" dense maxlength="10" placeholder="學生身分證字號"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" class="text-h6" align-self="center">密碼</v-col>
                    <v-col cols="9">
                        <v-text-field v-model="password" type="password" :rules="passwordRules" outlined required hide-details="auto" dense placeholder="校務系統密碼"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn type="submit" href="https://webap.nkust.edu.tw/nkust/ag_pro/ag319.jsp" color="#00BFFF">忘記密碼</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn type="submit" @click.prevent="login" color="#00BFFF" :loading="submitButtonLoading">登入</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        <div class="announcement text-left pl-2">
            <p v-for="(announcement, index) in announcements" :key="index" style="word-wrap: break-word">{{ index + 1 }}.{{ announcement.content }}</p>
        </div>
    </div>
</template>

<script>
import api from "../api/api.js";
import ordererVisitorAppBar from "../components/OrdererVisitorAppBar.vue";

export default {
    name: "Login",

    components: {
        ordererVisitorAppBar,
    },

    data() {
        return {
            infoValid: false,
            account: "",
            password: "",
            accountRules: [v => !!v || "帳號不得為空"],
            passwordRules: [v => !!v || "密碼不得為空"],
            submitButtonLoading: false,
            isCheckingTokenValid: true,
            announcements: [],
            isGetAnnouncements: false,
        };
    },

    mounted() {
        this.checkTokenIsValid();
        this.getAnnouncements();
    },

    methods: {
        login() {
            if (this.infoValid) {
                this.submitButtonLoading = true;

                api.login({
                    personal_id: this.account,
                    password: this.password,
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.$cookies.set("Authorization", "Bearer " + response.data["token"], "1d");
                            //如果是在學生，直接導向首頁。如果是校友，則導到選擇學制頁面
                            if (response.data["type"] === "member") {
                                this.$router.replace("/academic-system-choose"); //改為都先到確認身分畫面
                            } else {
                                this.$router.replace("/academic-system-choose");
                            }
                        } else {
                            this.$toasted.show("帳號或密碼錯誤。", {
                                type: "error",
                                duration: 2000,
                            });
                        }
                    })
                    .catch(() => {
                        this.$toasted.show("伺服器錯誤，請稍後再試", {
                            type: "error",
                            duration: 2000,
                        });
                    })
                    .finally(() => {
                        this.submitButtonLoading = false;
                    });
            }
        },

        checkTokenIsValid() {
            let token = this.$cookies.get("Authorization");

            if (!token) {
                this.isCheckingTokenValid = false;
                return;
            }

            api.me()
                .then(response => {
                    if (response.status === 200) {
                        this.$router.replace("/");
                    }
                })
                .catch(() => {
                    this.$toasted.show("登入逾期，請重新登入。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isCheckingTokenValid = false;
                });
        },

        getAnnouncements() {
            this.isGetAnnouncements = false;
            api.getAnnouncements()
                .then(response => {
                    this.announcements = response.data;
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetAnnouncements = true;
                });
        },
    },
};
</script>

<style scoped>
.container {
    margin: 0 auto;
    width: 30%;
    min-width: 300px;
    border: 2px solid #00aa00;
}

.announcement {
    margin: 0 auto;
    width: 50%;
    min-width: 300px;
    border: 2px solid #00aa00;
}

@media screen and (max-width: 600px) {
    .container {
        width: 80%;
    }

    .announcement {
        width: 90%;
    }
}
</style>
