<template>
    <div>
        <v-dialog v-model="isShowDialog">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on">
                    <v-icon>mdi-clipboard-edit-outline</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> 編輯管理類別-{{ adminGroup.name }} </v-card-title>
                <v-card-text>
                    <v-card v-for="(page, index) in pages" :key="index" class="my-2">
                        <v-card-title class="text-h6 pa-0" :class="{ 'page-name-valid': isPageValid(page), 'page-name-invalid': !isPageValid(page) }">
                            <v-btn class="ma-0" text block @click="pageNameButtonClick(page)">{{ page.name }}</v-btn>
                        </v-card-title>
                        <v-card-text class="d-flex flex-wrap">
                            <v-checkbox v-if="page.page_action.length" v-model="selectedAllArray[index]" label="全選" @click="selectAllPageAction(index)" :disabled="isPageDisabled(page)" :class="{ 'page-action-name-valid': selectedAllArray[index], 'page-action-name-invalid': !selectedAllArray[index] }" dense hide-details="auto"></v-checkbox>
                            <v-checkbox v-for="(pageAction, index) in page.page_action" :key="index" class="mx-2" v-model="adminGroupTemp.admin_group_page_action_right" @click="isSelectAllPageAction" :disabled="isPageDisabled(page)" :class="{ 'page-action-name-valid': isPageActionValid(pageAction), 'page-action-name-invalid': !isPageActionValid(pageAction) }" :value="pageAction" :label="pageAction.name" multiple dense hide-details="auto"></v-checkbox>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="primary" @click="createAdminGroupPageRight" :loading="submitButtonLoading">儲存</v-btn>
                    <v-btn @click="isShowDialog = false" :disabled="submitButtonLoading">取消</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "../api/api.js";

export default {
    name: "ModifyUserGroupDialog",

    props: {
        adminGroup: Object,
        pages: Array,
        isGetAdminGroups: Boolean,
    },

    data() {
        return {
            isShowDialog: false,
            adminGroupTemp: {
                admin_group_page_right: [],
                admin_group_page_action_right: [],
            },
            selectedAllArray: [],
            submitButtonLoading: false,
        };
    },

    watch: {
        isGetAdminGroups(val) {
            if (val === true) {
                this.resetValue();
            }
        },

        isShowDialog(val) {
            if (val === false) {
                this.resetValue();
            }
        },
    },

    mounted() {
        this.resetValue();
    },

    methods: {
        isPageValid(page) {
            return this.adminGroupTemp.admin_group_page_right.findIndex(pageRight => pageRight.id === page.id) != -1;
        },

        isPageActionValid(pageAction) {
            return this.adminGroupTemp.admin_group_page_action_right.findIndex(pageActionRight => pageActionRight.id === pageAction.id) != -1;
        },

        pageNameButtonClick(page) {
            let index = this.adminGroupTemp.admin_group_page_right.findIndex(pageRight => pageRight.id === page.id);

            if (index != -1) {
                this.adminGroupTemp.admin_group_page_right.splice(index, 1);
                this.deletePageRight(page);
            } else {
                let pageTemp = { id: page.id, name: page.name, key: page.key };
                this.adminGroupTemp.admin_group_page_right.push(pageTemp);
            }

            this.isSelectAllPageAction();
        },

        isPageDisabled(page) {
            let index = this.adminGroupTemp.admin_group_page_right.findIndex(pageRight => pageRight.id === page.id);

            if (index != -1) {
                return false;
            } else {
                return true;
            }
        },

        selectAllPageAction(pageIndex) {
            if (this.selectedAllArray[pageIndex] === true) {
                this.pages[pageIndex].page_action.forEach(element => {
                    if (this.adminGroupTemp.admin_group_page_action_right.findIndex(pageActionRight => pageActionRight.id === element.id) === -1) {
                        this.adminGroupTemp.admin_group_page_action_right.push(element);
                    }
                });
            } else {
                this.adminGroupTemp.admin_group_page_action_right = this.adminGroupTemp.admin_group_page_action_right.filter(element => element.page_id != this.pages[pageIndex].id);
            }
        },

        isSelectAllPageAction() {
            this.pages.forEach((page, index) => {
                for (let i = 0; i < page.page_action.length; i++) {
                    if (this.adminGroupTemp.admin_group_page_action_right.findIndex(element => element.id === page.page_action[i].id) != -1) {
                        this.selectedAllArray[index] = true;
                    } else {
                        this.selectedAllArray[index] = false;
                        break;
                    }
                }
            });
        },

        deletePageRight(page) {
            this.adminGroupTemp.admin_group_page_action_right = this.adminGroupTemp.admin_group_page_action_right.filter(pageAction => pageAction.page_id != page.id);
        },

        createAdminGroupPageRight() {
            this.submitButtonLoading = true;

            api.createAdminGroupPageRight({
                admin_group_id: this.adminGroup.id,
                admin_group_page_right: this.adminGroupTemp.admin_group_page_right,
                admin_group_page_action_right: this.adminGroupTemp.admin_group_page_action_right,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.$emit("getAdminGroups");
                        this.isShowDialog = false;

                        this.$toasted.show("更新成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("更新失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("更新失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },

        resetValue() {
            this.selectedAllArray = new Array(this.pages.length);
            this.adminGroupTemp = JSON.parse(JSON.stringify(this.adminGroup));
            this.isSelectAllPageAction();
        },
    },
};
</script>

<style scoped>
.v-dialog {
    width: 70%;
}

.v-btn {
    margin: 5px;
}

.page-name-invalid {
    background-color: #cfd8dc;
}

.page-name-valid {
    background-color: #29b6f6;
}

.page-action-name-invalid {
    border: 2px #dedede solid;
    border-radius: 4px;
}

.page-action-name-valid {
    border: 2px #80d8ff solid;
    border-radius: 2px;
}
</style>
