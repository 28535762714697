<template>
    <div>
        <span>Copyright © 2022 國立高雄科技大學 NKUST</span>
    </div>
</template>
<script>
export default {
    name: "CopyRight",
};
</script>
<style scoped>
.span {
    border-top: 2px solid #000000;
}
</style>
