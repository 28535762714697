import axios from "./AxiosPlugin";
axios.defaults.timeout = 60000;
// axios.defaults.baseURL = "https://pay.nkust.edu.tw:9999";
// const domain = "/";
const domain = "https://pay.nkust.edu.tw:9999/";
// const domain = window.location.hostname + "/";
const url = "api/";

export default {
    register(data) {
        return axios.post(domain + url + "user/register", data); //註冊用API暫用不到
    },

    login(data) {
        return axios.post(domain + url + "user/register", data);
    },

    loginOfAdmin(data) {
        return axios.post(domain + url + "user/login-admin", data);
    },

    logout() {
        return axios.post(domain + url + "user/logout");
    },

    me() {
        return axios.get(domain + url + "user");
    },

    createUserEmail(data) {
        return axios.post(domain + url + "useremail", data);
    },
    getUserByAccount(data) {
        return axios.get(domain + url + "user/by-account", data);
    },

    getUserSamePersonalId() {
        return axios.get(domain + url + "user/same-personal-id");
    },

    getAdminGroups() {
        return axios.get(domain + url + "admin-groups");
    },

    createAdminGroup(data) {
        return axios.post(domain + url + "admin-groups", data);
    },

    updateAdminGroup(data) {
        return axios.put(domain + url + "admin-groups/" + data.id, data);
    },

    deleteAdminGroup(data) {
        return axios.delete(domain + url + "admin-groups/" + data.id);
    },

    createUserAdminGroup(data) {
        return axios.post(domain + url + "user-admin-groups", data);
    },
    getAdminDepartment() {
        return axios.get(domain + url + "admin-groups/department");
    },

    deleteUserAdminGroup(data) {
        return axios.delete(domain + url + "user-admin-groups/" + data.id);
    },

    getPages() {
        return axios.get(domain + url + "pages");
    },

    createAdminGroupPageRight(data) {
        return axios.post(domain + url + "admin-group-page-rights", data);
    },

    getDocumentClasses() {
        return axios.get(domain + url + "document-classes");
    },

    createDocumentClass(data) {
        return axios.post(domain + url + "document-classes", data);
    },

    updateDocumentClass(data) {
        return axios.put(domain + url + "document-classes/" + data.id, data);
    },

    deleteDocumentClass(data) {
        return axios.delete(domain + url + "document-classes/" + data.id);
    },

    createDocument(data) {
        return axios.post(domain + url + "documents", data);
    },

    updateDocument(data) {
        return axios.put(domain + url + "documents/" + data.id, data);
    },

    deleteDocument(data) {
        return axios.delete(domain + url + "documents/" + data.id);
    },

    getPayments() {
        return axios.get(domain + url + "payments");
    },

    createPayment(data) {
        return axios.post(domain + url + "payments", data);
    },

    updatePayment(data) {
        return axios.put(domain + url + "payments/" + data.id, data);
    },

    deletePayment(data) {
        return axios.delete(domain + url + "payments/" + data.id);
    },

    getOrders(data) {
        return axios.get(domain + url + "orders", data);
    },

    getOrder(data) {
        return axios.get(domain + url + "orders/" + data.id);
    },

    createOrder(data) {
        return axios.post(domain + url + "orders", data);
    },

    updateOrder(data) {
        return axios.put(domain + url + "orders/update/" + data.id, data);
    },

    revokeOrder(data) {
        return axios.post(domain + url + "orders/revoke", data);
    },

    conclusionOrder(data) {
        return axios.post(domain + url + "orders/conclusion", data);
    },

    getApplicationForm(data) {
        return axios.get(domain + url + "applicationform/pdf/" + data.id);
    },

    paidOrder(data) {
        return axios.put(domain + url + "orders/paid/" + data.id, data);
    },

    orderUpdateNextProgress(data) {
        return axios.put(domain + url + "orders/next-progress/" + data.id);
    },

    getDeliveries() {
        return axios.get(domain + url + "deliveries");
    },

    createDelivery(data) {
        return axios.post(domain + url + "deliveries", data);
    },

    updateDelivery(data) {
        return axios.put(domain + url + "deliveries/" + data.id, data);
    },

    deleteDelivery(data) {
        return axios.delete(domain + url + "deliveries/" + data.id);
    },

    getProgresses() {
        return axios.get(domain + url + "progresses");
    },

    createProgress(data) {
        return axios.post(domain + url + "progresses", data);
    },

    updateProgress(data) {
        return axios.put(domain + url + "progresses/" + data.id, data);
    },

    deleteProgress(data) {
        return axios.delete(domain + url + "progresses/" + data.id);
    },

    getAcademicYears() {
        return axios.get(domain + url + "academic-years");
    },

    createAcademicYear(data) {
        return axios.post(domain + url + "academic-years", data);
    },

    updateAcademicYear(data) {
        return axios.put(domain + url + "academic-years/" + data.id, data);
    },

    deleteAcademicYear(data) {
        return axios.delete(domain + url + "academic-years/" + data.id);
    },

    getShoppingCarts() {
        return axios.get(domain + url + "shopping-carts");
    },

    countShoppingCarts() {
        return axios.get(domain + url + "shopping-carts/count");
    },

    createShoppingCart(data) {
        return axios.post(domain + url + "shopping-carts", data);
    },

    updateShoppingCart(data) {
        return axios.put(domain + url + "shopping-carts/" + data.id, data);
    },

    deleteShoppingCart(data) {
        return axios.delete(domain + url + "shopping-carts/" + data.id, data);
    },

    linePayPaymentsRequest(data) {
        return axios.post(domain + url + "linePay/v2/payments/request/" + data.order_id, data);
    },

    linePayPaymentsConfirm(data) {
        return axios.post(domain + url + "linePay/v2/payments/confirm", data);
    },

    getAnnouncements() {
        return axios.get(domain + url + "announcements");
    },

    createAnnouncement(data) {
        return axios.post(domain + url + "announcements", data);
    },

    updateAnnouncement(data) {
        return axios.put(domain + url + "announcements/" + data.id, data);
    },

    deleteAnnouncement(data) {
        return axios.delete(domain + url + "announcements/" + data.id);
    },

    getAreas() {
        return axios.get(domain + url + "areas");
    },

    createArea(data) {
        return axios.post(domain + url + "areas", data);
    },

    updateArea(data) {
        return axios.put(domain + url + "areas/" + data.id, data);
    },

    deleteArea(data) {
        return axios.delete(domain + url + "areas/" + data.id);
    },

    getAccounts() {
        return axios.get(domain + url + "accounts");
    },

    createAccount(data) {
        return axios.post(domain + url + "accounts", data);
    },

    updateAccount(data) {
        return axios.put(domain + url + "accounts/" + data.id, data);
    },

    deleteAccount(data) {
        return axios.delete(domain + url + "accounts/" + data.id);
    },

    getLinePayPayments(data) {
        return axios.get(domain + url + "linePay-payment", data);
    },
    getPdfProofPayments(data) {
        return axios.get(domain + url + "proof/pdf/" + data.order_id, data);
        // return axios(domain + url + "proof/pdf/" + data.order_id, { method: "GET", responseType: "blob", body: data, dataType: "jsonp" });
    },
    getEvent(data) {
        return axios.get(domain + url + "assign/getevnet/" + data.uuid, data);
    },
    updateEvent(data) {
        return axios.put(domain + url + "assign/" + data.uuid, data);
    },
    updateAdminAreaRights(data) {
        return axios.post(domain + url + "adminarearights/create", data);
    },
    getAdminAreaRight(data) {
        return axios.get(domain + url + "adminarearights/getAdminArea", data);
    },
    getAdminAreaRightID(data) {
        return axios.post(domain + url + "adminarearights/getAdminAreaRightID", data);
    },
    getlockerqrcode(data) {
        return axios.get(domain + url + "lockerqrcode/getqrcode/" + data.order_id, data);
    },
    updateqrcode() {
        return axios.get(domain + url + "lockerqrcode/updateqrcode");
    },
    createAssign(data) {
        return axios.post(domain + url + "assign/createassign", data);
    },
    createAdminDepartment(data) {
        return axios.post(domain + url + "admindepartment/create", data);
    },
    getAdminDepartmentGroup() {
        return axios.get(domain + url + "admindepartment/get");
    },
    updateAdminDepartmentGroup(data) {
        return axios.put(domain + url + "admindepartment/" + data.id, data);
    },
    deleteAdminDepartment(data) {
        return axios.delete(domain + url + "admindepartment/" + data.id);
    },
    getAdminDepartmentsGroup() {
        return axios.get(domain + url + "admindepartment/group/get"); //取得學校部門名稱的陣列
    },
    getTranscrips(data) {
        return axios.post(domain + url + "transcrips/getTranscrips", data);
    },
    getTermTranscrips(data) {
        return axios.post(domain + url + "transcrips/getTermTranscrips", data);
    },
    createExcel(data) {
        return axios.post(domain + url + "layoutexcel/excel", data);
    },

    printFinancialStatement(data) {
        return axios.post(domain + url + "printfinancialstatement/pdf", data);
    },
};
