<template>
    <div>
        <v-dialog v-model="isShowDeleteConfirmDialog" width="70%">
            <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn small color="primary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-map-marker</v-icon>
                </v-btn>
            </template> -->
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> 校區與部門設定 </v-card-title>
                <!-- <v-card-text class="d-flex flex-wrap"> -->
                <!-- <v-checkbox v-if="page.page_action.length" v-model="selectedAllArray[index]" label="全選" @click="selectAllPageAction(index)" :disabled="isPageDisabled(page)" :class="{ 'page-action-name-valid': selectedAllArray[index], 'page-action-name-invalid': !selectedAllArray[index] }" dense hide-details="auto"></v-checkbox> -->
                <!-- <v-checkbox v-for="(area, index) in areas" :key="index" class="mx-2" v-model="area_set" :value="area" :label="area.name" multiple dense hide-details="auto"></v-checkbox> -->
                <!-- </v-card-text> -->
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="updateAdminAreaRights" :loading="submitButtonLoading"> 儲存 </v-btn>
                    <v-btn @click="isShowDeleteConfirmDialog = false" :disabled="submitButtonLoading"> 取消 </v-btn>
                </v-card-actions> -->
                <v-container>
                    <v-row class="container-header each-item-row">
                        <v-col cols="5" class="same-category-each-cell">選擇校區 目前為:{{ areas[area_set].name }}</v-col>
                        <v-col cols="5" class="same-category-each-cell">選擇部門 目前為:{{ admindeparts[admindeparts_set].name }}</v-col>
                        <v-col cols="2" class="same-category-each-cell">操作</v-col>
                    </v-row>
                    <v-row class="different-category-row">
                        <v-col>
                            <v-radio-group v-model="area_set" row>
                                <v-radio class="mx-2" v-for="(area, index) in areas" :key="index" :label="area.name" :value="area"></v-radio>
                            </v-radio-group>
                            <!-- <v-checkbox v-for="(area, index) in areas" :key="index" class="mx-2" v-model="area_set" :value="area" :label="area.name" multiple dense hide-details="auto"></v-checkbox> -->
                        </v-col>
                        <!-- <v-checkbox v-for="(admindepart, index) in admindeparts" :key="index" class="mx-2" v-model="admindeparts_set" :value="admindepart" :label="admindepart.name" multiple dense hide-details="auto"></v-checkbox> -->
                        <v-col>
                            <v-radio-group v-model="admindeparts_set" row>
                                <v-radio class="mx-2" v-for="(admindepart, index) in admindeparts" :key="index" :label="admindepart.name" :value="admindepart"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="2">
                            <v-btn color="primary" @click="updateAdminAreaRights" :loading="submitButtonLoading"> 儲存 </v-btn>
                            <v-btn @click="isShowDeleteConfirmDialog = false" :disabled="submitButtonLoading"> 取消 </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "../api/api.js";

export default {
    name: "ModifyAreaRightDialog",

    data() {
        return {
            isShowDeleteConfirmDialog: false,
            submitButtonLoading: false,
            areas: {
                0: {
                    id: 0,
                    name: "無",
                    valid: true,
                },
            },
            area_set: -1,
            admindeparts: {
                0: {
                    id: 0,
                    name: "無",
                    valid: true,
                },
            },
            admindeparts_set: -1,
            adminGroup: [],
        };
    },
    mounted() {
        this.getAreas();
        this.getAdminDepartmentGroup();
    },
    methods: {
        init(adminGroup) {
            this.isShowDeleteConfirmDialog = true;
            this.adminGroup = adminGroup;
            this.getAdminAreaRightID();
        },
        getAreas() {
            this.isGetAreas = false;
            api.getAreas()
                .then(response => {
                    this.areas = response.data;
                    // console.log(this.areas);
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.isGetAreas = true;
                });
        },

        getAdminAreaRightID() {
            api.getAdminAreaRightID({
                adminGroup_id: this.adminGroup.id,
            })
                .then(response => {
                    let adminarearightid = response.data;
                    // console.log(adminarearightid);
                    this.area_set = adminarearightid[0].area_id - 1;
                    this.admindeparts_set = adminarearightid[0].admin_department_id - 1;
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        updateAdminAreaRights() {
            api.updateAdminAreaRights({
                user_id: this.adminGroup.id,
                area_id: this.area_set.id,
                admindepart_id: this.admindeparts_set.id,
            })
                .then(response => {
                    if (response.status === 200) {
                        // console.log(this.area_set);
                        // console.log(this.admindeparts_set);
                        this.$toasted.show("修改成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("新增失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("新增失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },

        getAdminDepartmentGroup() {
            api.getAdminDepartmentGroup()
                .then(response => {
                    this.admindeparts = response.data;
                })
                .catch(() => {
                    this.$toasted.show("新增失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },
    },
};
</script>

<style scoped>
.v-chip {
    margin-left: 5px;
    margin-right: 5px;
}

.container {
    border: 2px solid #000000;
}

.col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 4px;
}

.v-btn {
    margin: 5px;
}

.same-category-each-cell {
    border-right: 1px solid #efefef;
}

.container-header {
    background-color: #dedede;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}

.different-category-row {
    border-bottom: 2px solid #000000;
}
</style>
