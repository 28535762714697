<template>
    <div>
        <v-app-bar dense>
            <v-app-bar-nav-icon v-if="isMobile" @click="drawer = true"></v-app-bar-nav-icon>
            <v-toolbar-title>
                <img src="https://www.nkust.edu.tw/var/file/0/1000/img/513/182513897.png" alt="國立高雄科技大學文件申請系統" style="height: 40px" />
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div v-if="!isMobile">
                <router-link to="#" @click.native="logout" style="margin-right: 10px">登出</router-link>
                <router-link to="/academic-system-choose" style="margin-right: 10px">個人資料</router-link>
                <router-link to="/" style="margin-right: 10px">首頁</router-link>
                <router-link to="/shopping-cart" style="margin-right: 10px">購物車 <v-badge color="green" :content="count" bordered overlap> </v-badge></router-link>
                <router-link to="/application-record" style="margin-right: 10px">申請紀錄</router-link>
            </div>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" absolute temporary v-if="isMobile">
            <v-list nav dense>
                <v-list-item-group>
                    <v-list-item to="/" style="margin-right: 10px">
                        <v-list-item-icon>
                            <v-icon>mdi-file-document</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>首頁</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/shopping-cart" style="margin-right: 10px">
                        <v-list-item-icon>
                            <v-icon>mdi-cart</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>購物車 <v-badge color="green" :content="count"> </v-badge></v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/application-record" style="margin-right: 10px">
                        <v-list-item-icon>
                            <v-icon>mdi-file-document-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>申請紀錄</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="#" @click.native="logout" style="margin-right: 10px">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>登出</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import api from "../api/api.js";

export default {
    name: "OrdererAppBar",

    data() {
        return {
            drawer: false,
            isMobile: [],
            count: "0",
        };
    },

    methods: {
        checkIsMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            return flag;
        },

        logout() {
            api.logout().finally(() => {
                this.$cookies.remove("Authorization");
                this.$cookies.remove("verified");
                this.$router.push("/login");
            });
        },

        countShoppingCart() {
            api.countShoppingCarts()
                .then(response => {
                    if (response.status === 200) {
                        this.count = String(response.data);
                    } else {
                        this.$toasted.show("獲取購物車數字失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取購物車數字失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                });
        },
    },

    mounted() {
        this.isMobile = this.checkIsMobile();
        this.countShoppingCart();
    },
};
</script>

<style scoped></style>
