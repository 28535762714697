<template>
    <div>
        <v-dialog v-model="isShowDialog">
            <template v-slot:activator="{ on, attrs }">
                <v-chip :color="admindepart.valid === true ? 'green' : 'red'" outlined v-bind="attrs" v-on="on">{{ admindepart.name }}</v-chip>
            </template>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> 編輯部門 </v-card-title>
                <v-container>
                    <v-row class="container-header each-item-row">
                        <v-col cols="6" class="same-category-each-cell">名稱</v-col>
                        <v-col cols="2" class="same-category-each-cell">顯示</v-col>
                        <v-col cols="4">操作</v-col>
                    </v-row>
                    <v-row style="border-bottom: 1px solid #000000">
                        <v-col cols="6">
                            <p v-if="!isEdit" class="ma-0" style="word-wrap: break-word">{{ admindepart.name }}</p>
                            <v-text-field v-if="isEdit" v-model="nameTemp" outlined hide-details="auto" dense maxlength="20"></v-text-field>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-center">
                            <p v-if="!isEdit" class="ma-0">{{ admindepart.valid === true ? "是" : "否" }}</p>
                            <v-checkbox v-if="isEdit" v-model="validTemp" hide-details="auto" dense></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-btn v-if="pageActions.data_setting_area_edit" @click="isEdit = true" color="primary" :disabled="isEdit">修改</v-btn>
                            <v-dialog v-model="isShowDeleteConfirmDialog" width="70%">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-if="pageActions.data_setting_area_delete" color="error" v-bind="attrs" v-on="on"> 刪除 </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="text-h5 grey lighten-2"> 確定要刪除此校區嗎? </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="deleteAdminDepartment" :loading="submitButtonLoading"> 刪除 </v-btn>
                                        <v-btn @click="isShowDeleteConfirmDialog = false" :disabled="submitButtonLoading"> 取消 </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-btn v-if="pageActions.data_setting_area_edit" @click="resetValue" :disabled="!isEdit || submitButtonLoading">取消</v-btn>
                            <v-btn v-if="pageActions.data_setting_area_edit" @click="updateAdminDepartmentGroup" color="success" :disabled="!isEdit" :loading="submitButtonLoading">儲存</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from "../api/api.js";

export default {
    name: "ModifyAdminDepartmentDialog",

    props: {
        pageActions: Object,
        admindepart: Object,
    },

    data() {
        return {
            isShowDialog: false,
            isEdit: false,
            isShowDeleteConfirmDialog: false,
            nameTemp: "",
            validTemp: false,
            submitButtonLoading: false,
        };
    },

    watch: {
        isShowDialog(val) {
            if (val === false) {
                this.resetValue();
            }
        },
    },

    methods: {
        updateAdminDepartmentGroup() {
            this.isEdit = false;
            this.submitButtonLoading = true;

            api.updateAdminDepartmentGroup({
                id: this.admindepart.id,
                name: this.nameTemp,
                valid: this.validTemp,
            })
                .then(response => {
                    if (response.status === 200) {
                        this.admindepart.name = this.nameTemp;
                        this.admindepart.valid = this.validTemp;

                        this.$toasted.show("修改成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("修改失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("獲取資料失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },

        deleteAdminDepartment() {
            this.submitButtonLoading = true;

            api.deleteAdminDepartment({
                id: this.admindepart.id,
            })
                .then(response => {
                    if (response.status === 202) {
                        this.isShowDeleteConfirmDialog = false;
                        this.isShowDialog = false;
                        this.$emit("deleteAdmindepart", this.admindepart);

                        this.$toasted.show("刪除成功。", {
                            type: "success",
                            duration: 2000,
                        });
                    } else {
                        this.$toasted.show("修改失敗，請稍後再試。", {
                            type: "error",
                            duration: 2000,
                        });
                    }
                })
                .catch(() => {
                    this.$toasted.show("修改失敗，請稍後再試。", {
                        type: "error",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    this.submitButtonLoading = false;
                });
        },

        resetValue() {
            this.isEdit = false;
            this.nameTemp = this.admindepart.name;
            this.validTemp = this.admindepart.valid;
        },
    },

    mounted() {
        this.resetValue();
    },
};
</script>

<style scoped>
.v-chip {
    margin-left: 5px;
    margin-right: 5px;
}

.container {
    border: 2px solid #000000;
}

.col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 4px;
}

.v-btn {
    margin: 5px;
}

.same-category-each-cell {
    border-right: 1px solid #efefef;
}

.container-header {
    background-color: #dedede;
}

.each-item-row {
    border-bottom: 1px solid #000000;
}
</style>
