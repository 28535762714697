import Vue from "vue";
import VueRouter from "vue-router";
import Register from "../views/Register.vue";
import Login from "../views/Login.vue";
import AcademicSystemChoose from "../views/AcademicSystemChoose.vue";
import Document from "../views/Document.vue";
import ApplicationRecord from "../views/ApplicationRecord.vue";
import ShoppingCart from "../views/ShoppingCart.vue";
import LinePayPaymentsConfirm from "../views/LinePayPaymentsConfirm.vue";
import LinePayPaymentsCancel from "../views/LinePayPaymentsCancel.vue";

import LoginOfAdmin from "../views/LoginOfAdmin.vue";
import ManagementOrder from "../views/ManagementOrder.vue";
import ManagementSetting from "../views/ManagementSetting.vue";
import FinancialStatement from "../views/FinancialStatement.vue";
import ManagementAdmin from "../views/ManagementAdmin.vue";

import PageNotFound from "../views/PageNotFound.vue";
import Identify from "../views/Identify.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/register",
        name: "register",
        component: Register,
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/admin",
        name: "admin",
        component: LoginOfAdmin,
    },
    {
        path: "/academic-system-choose",
        name: "academicSystemChoose",
        component: AcademicSystemChoose,
    },
    {
        path: "/admin-order",
        name: "managementOrder",
        component: ManagementOrder,
    },
    {
        path: "/admin-setting",
        name: "managementSetting",
        component: ManagementSetting,
    },
    {
        path: "/admin-financial-statement",
        name: "financialStatement",
        component: FinancialStatement,
    },
    {
        path: "/admin-admin",
        name: "managementAdmin",
        component: ManagementAdmin,
    },
    {
        path: "/",
        name: "document",
        component: Document,
    },
    {
        path: "/shopping-cart",
        name: "shoppingCart",
        component: ShoppingCart,
    },
    {
        path: "/application-record",
        name: "applicationRecord",
        component: ApplicationRecord,
    },
    {
        path: "/linePay-v2-payments-confirm",
        name: "linePayPaymentsConfirm",
        component: LinePayPaymentsConfirm,
    },
    {
        path: "/linePay-v2-payments-cancel",
        name: "linePayPaymentsCancel",
        component: LinePayPaymentsCancel,
    },
    {
        path: "*",
        name: "pageNotFound",
        component: PageNotFound,
    },
    {
        path: "/identify",
        name: "identify",
        component: Identify,
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
