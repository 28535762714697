import axios from "axios";
import Vue from "vue";
import router from "@/router";

const service = axios.create({
    timeout: 10 * 2000,
    headers: { Accept: "application/json" },
});

service.interceptors.request.use(
    config => {
        if (Vue.$cookies.get("Authorization")) {
            config.headers.Authorization = Vue.$cookies.get("Authorization");
        }
        return config;
    },
    error => {
        return Promise.resolve(error.response);
    },
);

service.interceptors.response.use(
    function Response(response) {
        return response;
    },
    function errorResponse(error) {
        if (error && error.response) {
            switch (error.response.status) {
                case 401:
                    Vue.toasted.show("請重新登入", {
                        type: "error",
                        duration: 2000,
                    });

                    if (router.currentRoute.path.includes("/admin")) {
                        router.replace("/admin");
                    } else {
                        router.replace("/login");
                    }

                    break;
                case 403:
                    Vue.toasted.show("請重新登入", {
                        type: "error",
                        duration: 2000,
                    });

                    if (router.currentRoute.path.includes("/admin")) {
                        router.replace("/admin");
                    } else {
                        router.replace("/login");
                    }

                    break;
                case 404:
                    break;
                case 500:
                    Vue.toasted.show("伺服器出錯", {
                        type: "error",
                        duration: 2000,
                    });
                    break;
                case 503:
                    Vue.toasted.show("服務失效", {
                        type: "error",
                        duration: 2000,
                    });
                    break;
                default:
                    console.log(`連接錯誤${error.response.status}`);
            }
        } else {
            console.log("連接到服務器失敗");
        }
        return Promise.resolve(error.response);
    },
);

export default service;
